import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TableModule , TableRowCollapseEvent, TableRowExpandEvent } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { ProjectService } from '../../../../../../shared/services/project.service';
import { CommonModule } from '@angular/common';
import { CheckboxModule } from 'primeng/checkbox';
import { TabViewModule } from 'primeng/tabview';
import { FireReviewerConfirmation } from '../../project-listing/modals/fire-reviewer/fire-reviewer.component';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FireReviewerPayload } from '../../project.interface';
import { ToastService } from '../../../../../../shared/services/toast.service';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { ChipModule } from 'primeng/chip';
import { InputTextModule } from 'primeng/inputtext';
import { FloatLabelModule } from 'primeng/floatlabel';
import { MultiSelectModule } from 'primeng/multiselect';
import { ReviewerDetailDialogService } from '../../../../../../shared/services/reviewer-detail-dialog.service';
import { TooltipModule } from 'primeng/tooltip';
import { RateFreelancersComponent } from '../../project-listing/modals/rate-freelancers/rate-freelancers.component';
import { FirmService } from '../../../../../../shared/services/firm.service';
import { UserClientVm } from '../../../../../../store/user/user.interface';
import { UserService } from '../../../../../../shared/services/user.service';
import { NotesService } from '../../../../../../shared/services/notes.service';
import { MessageComponentService } from '../../../message/message.component.service';
import { SendMailModalComponent } from '../modals/send-mail/send-mail.component';
import { constants } from '../../../../../../shared/constants/constants';

@Component({
  selector: 'app-staff-tab',
  standalone: true,
  imports: [TableModule, ButtonModule, IconFieldModule, InputIconModule, CommonModule, CheckboxModule, TabViewModule, DialogModule, DropdownModule, 
    FormsModule, ChipModule, InputTextModule, FloatLabelModule, MultiSelectModule, TooltipModule],
  providers: [DialogService],
  templateUrl: './staff-tab.component.html',
  styleUrl: './staff-tab.component.scss'
})
export class StaffTabComponent implements OnInit{

  @Input() projectId!: any;
  @Input() projectStaffList!: any;
  @Output() 
  updateAndReInitializeStaff: EventEmitter<void> = new EventEmitter();
  @Input()
  projectDetails! : any;
  expandedRows = {};
  checked: boolean = false;
  visible: boolean = false;
  emailType = constants.emailType;
  
  projectPositionsListed: number = 0;
  projectPositionsFilled: number = 0;
  ref: DynamicDialogRef | undefined;
  user!: UserClientVm;
  firmId!: string;
  
  constructor(
    private projectService: ProjectService, 
    public dialogService: DialogService,
    private toastService: ToastService, 
    private reviewerDetailDialogService: ReviewerDetailDialogService,
    private firmService: FirmService,
    private userService: UserService,
    private notesService: NotesService,
    private messageCompoenentService: MessageComponentService
  ) { }

  ngOnInit(): void {
    this.user = this.userService.getSessionUser();
    this.firmId = this.user.firm._id;
  }

  getProjectStaffList(): void {
    this.projectStaffList = {};
    if (this.projectId)
      this.projectService.getProjectStaffList(this.projectId).subscribe({
        next: (resp) => {
          this.projectPositionsListed = resp.positionsListed;
          this.projectPositionsFilled = resp.positionsFilled;
          this.projectStaffList = resp.allStaff;
          this.projectStaffList.map((role: any) => {
            role.currentlyAssigned = [];
            role.formerlyAssigned = [];
            if (role.positionsList && role.positionsList.length) {
              role.positionsList.map((position: any) => {
                if (position.status !== 'QUIT' && position.status !== 'FIRED' && position.status !== 'COMPLETED') {
                  role.currentlyAssigned.push(position);
                }
                else {
                  role.formerlyAssigned.push(position);
                }
              });
            }
          });
        }
      })
  }

  showFireReviewerDialog($event:Event, reviewerId: string, positionId: string, role: any, firstName: string, lastName: string) {
    $event.stopPropagation();
    this.ref = this.dialogService.open(FireReviewerConfirmation, {
      header: 'Confirmation',
      width: '50vw',
      contentStyle: { overflow: 'auto' },
      breakpoints: {
        '960px': '75vw',
        '640px': '90vw'
      }
    });

    this.ref.onClose.subscribe((data: FireReviewerPayload) => {
      if (data) {
        this.projectService.fireReviewerFromProject(reviewerId, positionId, data.endMessage, data.isDRSSubmittedChecked).subscribe({
          next: (response) => {
            response.isDRSSubmitted = !!data.isDRSSubmittedChecked;
            this.toastService.showSuccess('Fired successfully');
            this.updateAndReInitializeStaff.emit();
            this.openRatingReviewerModal({
              reviewerId, 
              positionId,
              projectId: this.projectId,
              roleId: role,
              firstName,
              lastName,
              projectDetails: this.projectDetails
            });
          },
          error: (error) => {
            this.toastService.showError('Error while firing reviewer');
          }
        });
      }
    });
  }

  async openRatingReviewerModal(data: any): Promise<void>{
    const firmFavorites = await this.firmService.getFirmFavourites(this.firmId || '');
    const rateReviewerModalRef = this.dialogService.open(RateFreelancersComponent, {
      header: 'Rate Freelancer',
      width: '50vw',
      contentStyle: { overflow: 'auto' },
      breakpoints: {
        '960px': '75vw',
        '640px': '90vw'
      },
      data: {
        ...data,
        firmFavorites: firmFavorites && firmFavorites.favorites.length ? firmFavorites.favorites : [],
        firmId: this.firmId
      }
    });

    rateReviewerModalRef.onClose.subscribe(async (modalResponse) => {
      if(modalResponse && modalResponse.isConfirm){
        const ratingInfo = {...modalResponse.ratingInfo};
        const roleReviewersRating: any = {
          rate: []
        };

        const rate = {
          position: ratingInfo.positionId,
          stars: ratingInfo.rating,
          role: ratingInfo.roleId,
          isRestricted: ratingInfo.isRestricted,
          workTogether: ratingInfo.workTogether
        }

        roleReviewersRating.rate.push(rate);

        try{
          await this.projectService.submitReviewerRating(this.projectId, roleReviewersRating);
          if(ratingInfo.notes){
            await this.notesService.addNotes(data.reviewerId, ratingInfo.notes);
          }
          this.toastService.showSuccess('Reviewer ratings submitted successfully');
          this.updateAndReInitializeStaff.emit();
        }catch(error){
          this.toastService.showError(`Error occured while submitting the ratings for reviewer ${data.firstName} ${data.lastName}`)
        }

      }
    })
  }

  onRowExpand(event: TableRowExpandEvent) { }

  onRowCollapse(event: TableRowCollapseEvent) { }

  async showMailDialog(projectId: string, emailType: string, firm: any, recipient: string, roleId: string, reviewerId: string) {
    const projectOwners = await this.firmService.getFirmProjectOwners(firm._id);
    this.dialogService.open(SendMailModalComponent, {
      header: 'Send Email',
      width: '50vw',
      height: '70vh',
      contentStyle: { overflow: 'auto' },
      breakpoints: {
        '960px': '75vw',
        '640px': '90vw'
      },
      data: {
        projectId,
        emailType,
        firm,
        recipient,
        roleId,
        reviewerId,
        projectOwners: projectOwners.users
      }
    });
  }

  openReviewerDialog(id:string){
    this.reviewerDetailDialogService.openDialog(id);
  }

  openCreateChatReviewer(position:any){

    let node = {
      projectId:position.project,
      roleId:position.role,
      reviewerId:position.reviewer._id,
      msgType: 'REVIEWER',
      reviewer: position.reviewer,
      status: position.status
    }
    this.messageCompoenentService.openCreateDiscussion(node);
  }

  openCreateChat(role:any){

    let node = {
      projectId: role.positionsList[0].project,
      roleId: role._id,
      reviewerId: null,
      msgType: "ROLE",
      title: role.roleType,
      status: role.status,
      node: role
    };
    this.messageCompoenentService.openNewMessageDialog(node);
  }

  openGroupChat(){

    let node = {
      projectId: this.projectDetails.id,
      roleId: null,
      reviewerId: null,
      msgType: "PROJECT",
      title: this.projectDetails.codeName,
      status: this.projectDetails.status,
      node: this.projectDetails
    };
    this.messageCompoenentService.openNewMessageDialog(node);
  }
}
