@if(docuSignEditorOpen){
    <edit-template (openDocuSignEditor)="openDocuSignEditor($event)" [isViewOnly]="isViewOnly" [projectId]="projectId"></edit-template>
} @else {
    <div class="grid grid-nogutter mb-3">
        <div class="col-12 md:col-6 sm:col-12 sm:mb-2">
            <h1 class="h4 m-0">Document Templates</h1>
            <div class="breadcrumb">
                <p-breadcrumb 
                    class="max-w-full" 
                    [model]="items" />
            </div>
        </div>
    </div>
    <div class="grid grid-nogutter">
        <div class="col-12 xl:col-3 lg:col-4  p-0 mb-3">
            <div class="table-options">
                <p-iconField iconPosition="right">
                    <p-inputIcon styleClass="pi pi-search" />
                    <input type="text" pInputText placeholder="Search Keywords " />
                </p-iconField>
            </div>
        </div>
        <div class="col-12 p-0">
            <p-table styleClass="p-datatable-sm p-datatable-sm--normal" [value]="templates" dataKey="_id" [tableStyle]="{ 'min-width': '60rem' }"
                [expandedRowKeys]="expandedRows" (onRowExpand)="onRowExpand($event)"
                (onRowCollapse)="onRowCollapse($event)">
                <ng-template pTemplate="header">
                    <tr>
                        <th pSortableColumn="name">
                            Template Name
                            <p-sortIcon field="name" />
                        </th>
                        <th style="width: 8%">Action</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" class="border-0" let-templates let-expanded="expanded">
                    <tr>
                        <td>
                            <p-checkbox [(ngModel)]="checked" [binary]="true" />
                            {{ templates.name }}
                        </td>
                        <td>
                            <div class="wrap-actions flex align-items-center gap-2">
                                <p-button [rounded]="true" [text]="true" icon="true" (onClick)="deleteTemplate($event, templates.templateId, templates)">
                                    <span class="red material-symbols-outlined">
                                        delete
                                    </span>
                                </p-button>
                                <p-button [rounded]="true" [text]="true" icon="true" (click)="showUploadDocumentDialog(templates)">
                                    <span class="material-symbols-outlined">
                                        add
                                    </span>
                                </p-button>
                                <p-button type="button" pRipple [pRowToggler]="templates" [text]="true" [rounded]="true"
                                    [plain]="true" [icon]="
                                    expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-up'
                                    " class="row-toggler" />
                            </div>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="rowexpansion" let-templates>
                    <tr>
                        <td colspan="7" class="p-0">
                            <div class="inner-wrap surface-100 p-0">
                                <p-table [value]="templates.documents" dataKey="_id">
                                    <ng-template pTemplate="body" let-documents>
                                        <tr>
                                            <td class="pl-40">{{ documents.name }}</td>
                                            <td class="pr-40" style="width: 12%">
                                                <div class="wrap-actions wrap-buttons-expandable">
                                                    <p-button [rounded]="true" [text]="true" icon="true" (click)="viewTemplateDocument(templates.templateId, documents.documentId, documents.name, templates)">
                                                        <span class="material-symbols-outlined">
                                                            visibility
                                                        </span>
                                                    </p-button>
                                                    <p-button [rounded]="true" [text]="true" icon="true" (onClick)="deleteTemplateDocument($event, documents.documentId, templates.templateId, templates)">
                                                        <span class="red material-symbols-outlined">
                                                            delete
                                                        </span>
                                                    </p-button>
                                                </div>
                                            </td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>

    <form [formGroup]="docusignUploadForm">
        <p-dialog [modal]="true" draggable="false" [(visible)]="visibleDocusignUploadModal"
            [breakpoints]="{ '1199px': '75vw', '767px': '90vw' }" [style]="{ width: '660px' }">

            <ng-template pTemplate="header">
                <div class="inline-flex align-items-center justify-content-center gap-2">
                    <div class="font-bold white-space-nowrap">
                        Upload File
                    </div>
                </div>
            </ng-template>
            <div class="grid grid-nogutter">
                <div class="col-12 p-0 flex justify-content-between align-items-center mt-3">
                    <p-floatLabel>
                        <input pInputText id="title" formControlName="docTitle" placeholder="Place your title here" />
                        <label for="title">Title</label>
                    </p-floatLabel>
                </div>
            </div>

            @if(docusignForm['docTitle'].dirty || docusignForm['docTitle'].touched){
                @if(docusignForm['docTitle'].errors?.['required']){
                    <div class="p-error ">This is required.</div>
                }
            }

            <div class="grid grid-nogutter">
                <div class="col-12 p-0 flex justify-content-between align-items-center mt-3">
                    <p-button (click)="docusignUpload.click()" [label]="'Select PDF Files'" icon="pi pi-plus" size="large"
                        [rounded]="true" />
                    <input #docusignUpload id="docusignUpload" type="file" class="hidden" value="..." name="file" accept="application/pdf" button-disable
                        (change)="onUploadChange($event)">
                </div>
            </div>

            @if(projectFiles && projectFiles.length){
                <div class="grid grid-nogutter">
                    <div class="col-12 p-0 flex justify-content-between align-items-center mt-3">
                        {{projectFiles[0].name}}
                    </div>
                </div>
            }

            <ng-template pTemplate="footer">
                <p-button type="submit" label="SAVE" size="large" [rounded]="true"
                    (onClick)="addDocumentInTemplate()" />
            </ng-template>
        </p-dialog>
    </form>
}