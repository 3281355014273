<div>
  <form [formGroup]="createForm">
    <div class="grid mt-2">
      <div class="col-12 lg:col-6 xl:lg:col-6 pb-3 pt-0 pl-0 pr-0 xl:pr-2">
        <p-floatLabel>
          <input formControlName="name" type="text" pInputText />
          <label for="name">Name</label>
        </p-floatLabel>
        <div class="pt-3">
          <p-dropdown
            [options]="statusOptions"
            formControlName="isActive"
            placeholder="Select Status"
            appendTo="body"
          >
          </p-dropdown>
        </div>
      </div>
    </div>
    <div class="flex justify-content-end gap-2">

      <p-button
        label="CANCEL"
        size="large"
        [rounded]="true"
        [outlined]="true"
        (onClick)="closeDialog()"
      />
      <p-button
        label="{{buttonText}}"
        size="large"
        [disabled]="!createForm.valid"
        [rounded]="true"
        (onClick)="onSubmit()"
      />
    </div>
  </form>
</div>
