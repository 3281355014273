import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AccordionModule } from 'primeng/accordion';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { ChipModule } from 'primeng/chip';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { TagModule } from 'primeng/tag';
import { HeadingComponent } from '../../../../shared/components/heading/heading.component';
import { ReviewerService } from '../../../../shared/services/reviewer.service';
import { Reviewer } from '../../../../store/reviewer/reviewer.interface';
import { Criteria, SelectedCriteries, SkillsCriteria, ReviewerCriteriaObject } from '../profile.interface';
import { FormsModule } from '@angular/forms';
import {ToastService} from '../../../../shared/services/toast.service'

@Component({
  selector: 'app-skills',
  standalone: true,
  imports: [CommonModule, ButtonModule, FormsModule, FloatLabelModule, CalendarModule, InputTextModule, DropdownModule, HeadingComponent, MultiSelectModule, AccordionModule, TagModule, FileUploadModule, ChipModule],
  templateUrl: './skills.component.html',
  styleUrl: './skills.component.scss'
})
export class SkillsComponent implements OnInit {
  dummy!: any[];
  headingButton = {
    url: '/reviewer/profile/education/create',
    label: 'Add credentials'
  }
  reviewer!: Reviewer;
  skillsCriteriaListing: SkillsCriteria = [];

  constructor(private reviewerService: ReviewerService, private toast:ToastService) { }


  ngOnInit() {
    this.reviewer = this.reviewerService.getReviewer();
    this.getUserSkillsByDynamicCriteria();
  }

  getUserSkillsByDynamicCriteria() {
    this.reviewerService.getFilteredCriteriaBasedUserSkills(this.reviewer._id).subscribe((resp: any) => {
      this.reviewerService.getReviewerCriteria(this.reviewer._id).subscribe((data: SelectedCriteries) => {
        let reviewerCriteriaObject: ReviewerCriteriaObject = {};
        if (data.length > 0) {
          reviewerCriteriaObject = data.reduce((obj, item) => {
            let wrap = {
              [item.criteriaName]: item
            };
            Object.assign(obj, wrap);
            return obj;
          }, {});
        }
        resp.map((d: Criteria) => {
          const name = d.name;
          d.criteriaSelectedOption = reviewerCriteriaObject[name.toString()] ? reviewerCriteriaObject[name.toString()].criteriaSelectedOption : [];
          const options: any = [];
          d.criteriaSelectedOption.map((option: any) => {
            const filterData = d.label.values.filter((value: any) => {
              return value.val === option.val;
            })

            options.push(...filterData)
          })
          d.criteriaSelectedOption = options;
        })
        this.skillsCriteriaListing = resp;

      })
    })
  }
  onRemoveChip(data: any, index: number) {
    const criteriaSelectedOption = this.skillsCriteriaListing[index].criteriaSelectedOption;
    const option = criteriaSelectedOption.filter((d: any) => {
      return d.val !== data.val;
    })
    this.skillsCriteriaListing[index].criteriaSelectedOption = option;
  }
  
  save(){
    let criterias:any = {};
    this.skillsCriteriaListing.map((d:any)=>{
      if(!criterias[d.name]){
        criterias[d.name] = {selectedOptions : d.criteriaSelectedOption, jobCategory: d.jobCategory.map((category: any) => category._id), criteriaId: d._id};
      }
    })
    const payload = {
      criterias: criterias,
      reviewerId: this.reviewer._id
    }
    this.reviewerService.saveCriteriaReviewer(payload).subscribe((d:any)=>{
      this.toast.showSuccess('Skills and Experience Updated Successfully')

    })
  }
}
