
import { HttpErrorResponse, HttpInterceptorFn, HttpStatusCode } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastService } from '../services/toast.service';
import { StorageService } from '../services/storage.service';
import { constants } from '../constants/constants';
import { AuthService } from '../services/auth.service';

export const AuthInterceptor: HttpInterceptorFn = (request, next) => {
    const toast = inject(ToastService);
    const auth = inject(AuthService);

    return next(request).pipe(
        catchError((error: HttpErrorResponse) => {
            
            
            if ([HttpStatusCode.Unauthorized, HttpStatusCode.Forbidden].includes(error.status)) {
                toast.showError("Session Timed Out!");
                auth.logout();
            }
            else if(!request.url.includes(constants.url.pastProject)){
                toast.showError(error.error.message);
            }
            return throwError(() => error);            
        }),
    );
}; 
