<div class="flex flex-row col-12">
    <div class="col-4">
        <form [formGroup]="templateForm">
            <div>
                <p-floatLabel>
                    <input type="subject" formControlName="subject" pInputText />
                    <label for="subject">Subject</label>
                </p-floatLabel>
            </div>
            <div class="mt-4">
                <p-floatLabel>
                    <textarea formControlName="content" rows="5" cols="30" pInputTextarea [autoResize]="true" placeholder="Write Message..."></textarea>
                    <label>Message</label>
                </p-floatLabel>
            </div>
            <div>
                <p-button
                label={{buttonText}}
                size="large"
                [disabled]="!templateForm.valid"
                [rounded]="true"
                (onClick)="onSubmit()"
                />
            </div>
        </form>
    </div>

    <div class="col-8">
        <p-table styleClass="p-datatable-sm p-datatable-sm--normal" [value]="template" [paginator]="true" [rows]="5" [tableStyle]="{ 'min-width': '30rem' }"
        [showCurrentPageReport]="true" autoLayout="true"
        currentPageReportTemplate="Showing {first} - {last} of {totalRecords}">
        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="subject">
                    Subject
                </th>
                <th pSortableColumn="message">
                    Message
                </th>
                <th>
                    Actions
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-template>
            <tr>
                <td>{{ template.subject }}</td>
                <td>{{ template.content }}</td>
                <td>
                    <div class="wrap-actions">
                        <p-button pTooltip="Edit" tooltipPosition="top" [rounded]="true" [text]="true" icon="true" (click)="onEdit(template)">
                            <span class="material-symbols-outlined">
                                edit
                            </span>
                        </p-button>
                        <p-button (click)="onDelete(template)" pTooltip="Delete" tooltipPosition="top" [rounded]="true" [text]="true" icon="true">
                            <span class="red material-symbols-outlined">
                                delete
                            </span>
                        </p-button>
                    </div>
                </td>
            </tr>
        </ng-template>
        </p-table>
    </div>
</div>

