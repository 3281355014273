<div header="Delete User" [style]="{ width: '32rem' }" class="align-items-center">
    <h5 class="block mb-5">{{config.data.heading}} </h5>
    
    @if(config.data.from === 'deleteReviewer'){
        <div class="col-12 p-0">
            <p class="m-0 text-sm text-black-alpha">Reason</p>
        </div>
        <div class="col-12 mb-3">
            <div class="flex flex-wrap gap-3">
                @for (dr of deleteReasons; track reason) {
                    <div class="flex align-items-center">
                        <p-radioButton 
                        [(ngModel)]="reason"
                            [value]="dr.value" />
                        <label for="ingredient1" class="ml-2">
                           {{dr.label}}
                        </label>
                    </div>
                }
            </div>
        </div>
    }
    
    <div class="col-12 p-0 mb-1">
        <p class="m-0 text-sm text-black-alpha">{{config.data.textAreaHeading}}</p>
    </div>
    <div class="flex align-items-center gap-3 mb-3">
        
        <textarea rows="3" [value]="endMessage" (input)="onValueChange($event)"  pInputText id="end-reason" class="flex-auto" autocomplete="off" ></textarea>
    </div>
   
    <div class="flex justify-content-end gap-2">
        <p-button label="Cancel" severity="secondary" (click)="closeDialog()" />
        <p-button [disabled]="config.data.from === 'deleteReviewer' ? !reason : !endMessage" label="OK" (click)="confirm(endMessage)" />
    </div>
</div>