import { Injectable } from '@angular/core';
import { ArchivePayload } from '../../modules/client/dashboard/project/project.interface';
import { HttpBaseService } from './http-base.service';
import { UserService } from './user.service';
import { StorageService } from './storage.service';
import { Observable, lastValueFrom } from 'rxjs';
import { FileUploadService } from './file-upload.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  constructor(private http: HttpBaseService, private userService: UserService, private fileUploadService: FileUploadService, 
    private storageService: StorageService) { }

  getProjects(queryObject:any) {
    const createdBy = this.getCurrentUserProjectQueryParam();
    const userId = this.userService.getSessionUser()._id;
    if(createdBy){
      queryObject.createdBy = userId;
    }
    return this.http.get('projects',{params:queryObject});
  }

  getProjectsOverview (searchMarkets: any) {
    let userId = this.userService.getSessionUser()._id;
    let createdBy = this.getCurrentUserProjectQueryParam();
    return this.http.get('projects/overview/' + searchMarkets + '?sessionUserId='+userId + createdBy);
  }

  getProjectsByFirmAndMarkets (firmIds: any, marketIds: any, status: any, options?: any) {
    let query = 'projects/codeNamesByMarketAndFirm?firms=' + firmIds + '&markets=' + marketIds.join(',');
    if (status && status.length) {
        query += '&status=' + status.join(',');
    }
    if (options && options.contractor) {
        query += '&contractor=true';
    }
    if (options && options.lastTwoWeeksArchived) {
        query += '&lastTwoWeeksArchived=true';
    }
    let createdBy = this.getCurrentUserProjectQueryParam();
    return this.http.get(`${query}${createdBy}`);
  }

  getMultipleProjectRoles (projectIds: any) {
    return this.http.get('projects/roles?projectIds=' + projectIds.join(','));
  }

  getProjectResponse(projectId: string) {
    return this.http.get('projects/'+ projectId + '/responses');
  }

  getProjectById(projectId: string): Observable<any> {
    return this.http.get('projects/'+ projectId);
  }

  projectArchive(projectId: string, payload:ArchivePayload){
    return this.http.post('projects/'+ projectId +'/archive',payload);
  }

  projectRestore(projectId: string, payload: {projectOwner: string}){
    return this.http.post('projects/'+ projectId +'/reactivate', payload);
  }

  getProjectStaffList(projectId: string, search?: string) {
    let query = search ? '?searchText=' + search : '';
    return this.http.get('projects/' + projectId + '/staffLists' + query);
  }

  getProjectsByOwner (owner: string, offset?: number, limit?: number) {
    return this.http.get('projects/owner/' + owner, {offset, limit});
  }

  getProjectRoles(projectId: string) {
    return this.http.get('projects/' + projectId + '/roles');
  }

  getProjectActiveRoles(projectId: string): Promise<Array<any>> {
    return lastValueFrom(this.http.get('projects/' + projectId + '/roles?status=ACTIVE'));
  }

  getProjectDocuments() {
    const userId = this.userService.getSessionUser()._id;
    return this.http.get('projects/documents?sessionUserId='+userId+ this.getCurrentUserProjectQueryParam(false));
  }

  updateProjectRole(projectId: string, roleId: string, requestPayload: any): Promise<any>{
    return lastValueFrom(this.http.put(`projects/${projectId}/roles/${roleId}`, requestPayload));
  }

  addShortListedReviewerForRole(projectId: string, roleId: string, requestPayload: any): Promise<any>{
    return lastValueFrom(this.http.put(`projects/${projectId}/roles/${roleId}/shortlistedReviewers`, requestPayload));
  }

  updateShortlistedPosition(projectId: string, roleId: string, requestPayload: any): Promise<any>{
    return lastValueFrom(this.http.put(`projects/${projectId}/roles/${roleId}/shortlistedReviewers/rank`, requestPayload));
  }

  removeShortListedReviewer(projectId: string, roleId: string, reviewerId: string): Promise<any> {
    return lastValueFrom(this.http.delete(`projects/${projectId}/roles/${roleId}/reviewer/${reviewerId}?type=shortlistedReviewers`, null));
  }

  removeOfferedQueueReviewer(projectId: string, roleId: string, reviewerId: string): Promise<any> {
    return lastValueFrom(this.http.delete(`projects/${projectId}/roles/${roleId}/reviewer/${reviewerId}?type=reviewers`, null));
  }

  updateProjectStep(projectId: string, step: number) {
    const updateInfo = {
        step
    };
    const userId = this.userService.getSessionUser()._id;
    return lastValueFrom(this.http.put(`projects/${projectId}?sessionUserId=${userId}`, updateInfo));
  };

  getCurrentUserProjectQueryParam(param?: any) {
    let showCurrentUserProjects = this.storageService.getCookieValueByKey('showCurrentUserProjects');
    let userId = this.userService.getSessionUser()._id;
    let queryParam = param ? '?' : '&'; 
    return showCurrentUserProjects === 'true' ? queryParam +'createdBy=' + userId  : '';
  }
  
  fireReviewerFromProject(reviewerId: string, positionId: string, reason: string, isDRSSubmitted: boolean){
    const url = `users/${reviewerId}/positions/${positionId}/fireReviewer`
    return this.http.put(url, {reason, isDRSSubmitted});
  }

  getRoleDetails(projectId:string,roleId:string){
    return this.http.get(`projects/${projectId}/roles/${roleId}`);
  }

  createProject(project: any): Observable<any>{
    const userId = this.userService.getSessionUser()._id;
    return this.http.post(`projects?sessionUserId=${userId}`, project);
  }

  updateProject(project: any, projectId: string): Observable<any>{
    const userId = this.userService.getSessionUser()._id;
    return this.http.put(`projects/${projectId}?sessionUserId=${userId}`, project);
  }

  updateDocuSignTemplate(document: any, fileOptions: any, templateName: string, templateId: string, docIndex: any) {
    const templateInfo = {
      uploadedDocuments: [document],
      templateName: templateName,
      templateId: templateId,
      docIndex: docIndex,
      fileOptions: JSON.stringify(fileOptions)
    };
    const endpoint = 'documents/docuSign/template/' + templateId;
    return this.fileUploadService.fileUpload(document, templateInfo, endpoint);
  }

  createProjectTemplate(projectId: string, firmId: string, uploadedDocuments: any, fileOptions: any, templateName: any, templateFields: any) {
    let templateInfo = {
      uploadedDocuments: uploadedDocuments,
      templateName: templateName,
      templateFields: JSON.stringify(templateFields),
      fileOptions: JSON.stringify(fileOptions)
    };
    let endpoint = 'documents/docuSign/project/' + projectId+'/firm/'+ firmId;
    return this.fileUploadService.fileUploadMultiple(uploadedDocuments, templateInfo, endpoint);
  }
  
  getPastProjectTimekeeping(){
    return this.http.get('my/pastProjects?allPositions=true');
  }

  getRolesByReviewerProject(projectId:[string]){
    return this.http.post('positions/getRolesByReviewerId', {projectId: projectId});
  }

  publishProject(projectId: string): Promise<any>{
    return lastValueFrom(this.http.post( `projects/${projectId}/publish`));
  }

  deleteProjectRole(projectId:string, roleId:string, reason:string) {
    const url = `projects/${projectId}/roles/${roleId}/endRole`;
    return this.http.put(url, { endMessage: reason});
  };

  reactiveRole(projectId:string, roleId:string, startDate:Date) {
    const url = `projects/${projectId}/roles/${roleId}/reactivate`;
    return this.http.put(url, { startDate: startDate});
  }
  
  uploadRequiredDocument(projectId: string, file: any, opts: any): Observable<any>{
    return this.fileUploadService.fileUpload(file, opts, `projects/${projectId}/documents`);
  }

  deleteDocument(projectId: string, documentId: string) {
    return this.http.delete("projects/" + projectId + "/documents/" + documentId);
  };

  getFirmManagers(offset: number, limit: number, firmId: string, role: string) {
    return this.http
        .get('firms/' + firmId + '/managers/' + role + '?offset=' + offset + '&limit=' + limit); 
  };

  getProjectsWithRolesReviewers (firmId: string,userRole: string) {
    let queryParam = "?markets=all";
    const createdBy = this.getCurrentUserProjectQueryParam();
    if (createdBy) {
      queryParam = `${queryParam}${createdBy}`;
    }   
    return this.http.post(`projects/minimal${queryParam}`,{firmId:firmId,userRole:userRole});
  };

  createProjectRating(firmId: string, requestPayload: any): Promise<any>{
    return lastValueFrom(this.http.post(`reviews/firms/${firmId}`, requestPayload));
  }

  submitReviewerRating(projectId: string, rateReviewerPayload: any): Promise<any>{
    return lastValueFrom(this.http.put(`projects/${projectId}/rateReviewers`, rateReviewerPayload));
  }
}
