import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { UserService } from '../../../../../../../shared/services/user.service';
import { DocumentService } from '../../../../../../../shared/services/document-service';
import { Details, UserClientVm, UserDetails } from '../../../../../../../store/user/user.interface';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { CommonModule } from '@angular/common';
@Component({
  selector: 'app-credentialing',
  standalone: true,
  imports: [TableModule, ButtonModule, CommonModule],
  templateUrl: './credentialing.component.html',
  styleUrl: './credentialing.component.scss'
})
export class CredentialingComponent implements OnInit {
  credentials: UserDetails = [];
  userObject!: UserClientVm;
  constructor(private ref: DynamicDialogRef, public config: DynamicDialogConfig, private userService: UserService,
    private documentService: DocumentService
  ) { }

  ngOnInit(): void {
    this.userObject = this.userService.getSessionUser();
    this.getAbDetails();
  }

  getAbDetails() {

    this.config.data.reviewer.tenants = this.config.data.reviewer.tenants.filter((d: any) => {
      return d.firm._id === this.userObject.firm._id;
    })
    this.userService.getAbdetails(this.config.data.reviewer._id).subscribe((resp: UserDetails) => {
      resp.map((d: Details) => {
        if (d.auditDocumentId) {
          d.downloadURL = this.documentService.getDocumentPathById(d.auditDocumentId);
        }
        if (d.certificate) {
          d.certificateDownloadURL = this.documentService.getDocumentPathById(d.certificate);
        }
      })
      this.credentials = resp;
    })
  }

  closeDialog() {
    this.ref.close();
  }

  backgroundCheck(type: string, data: Details) {
    this.ref.close({ from: type, data: data });
  }

  downloadDocument(url: any) {
    window.open(url, '_self');
  }
}
