<div class="align-items-center cancel-confirmation-container">
    <div class="col-12 mb-3 p-0">
        <div class="w-full flex flex-column gap-3 pb-3">
            <div class="align-items-center flex justify-content-between">
                <div class="flex align-items-center gap-4 text-sm">
                    <p>
                        {{cancelOfferInfo.role.unfilledPositions ? (cancelOfferInfo.role.unfilledPositions +' unfilled positions will be canceled and a'): 'A'}}ll outstanding offers will be removed from <b>{{cancelOfferInfo.role.roleType}}</b>
                    </p>
                </div>
            </div>
            <div class="flex gap-1">
                Please click "OK" to continue. If not,please click "Cancel"
            </div>
            
        </div>
    </div>
    <div class="flex justify-content-end gap-2">
        <p-button label="Cancel" [text]="true" [outlined]="true" [size]="'large'" (click)="onConfirm(false)" />
        <p-button label="Ok" [size]="'large'" (click)="onConfirm(true)" />
    </div>
</div>
