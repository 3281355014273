@if(config.data.conflictQuestions.length > 0){
    <div header="Screening Questions" class="align-items-center">
        <div class="col-12 mb-3 p-0">
            <p><b>Please answer these Screening Questions to proceed:</b></p>
        </div>
        <div class="col-12 mb-3 p-0">
            <p>{{currentIndex+1}}) {{config.data.conflictQuestions[currentIndex].title}}</p>
        </div>
       
        <div class="flex justify-content-end gap-2">
            <p-button label="NO" (click)="selectAnswer(false)"  [severity]="config.data.conflictQuestions[currentIndex].answer ? 'secondary' : 'primary'" />
            <p-button label="YES" (click)="selectAnswer(true)"  [severity]="config.data.conflictQuestions[currentIndex].answer ? 'primary' : 'secondary'"  />
        </div>
    </div>
}
