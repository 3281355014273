import { Component, OnInit } from '@angular/core';
import {
    DialogService,
    DynamicDialogConfig,
    DynamicDialogModule,
    DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { ButtonModule } from 'primeng/button';
import {
    FormsModule,
} from '@angular/forms';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputTextModule } from 'primeng/inputtext';
import { CommonModule } from '@angular/common';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { TalentPool } from '../../../../../../../../shared/interfaces/talent-pool.interface';
import { InputSwitchModule } from 'primeng/inputswitch';
import { MultiSelectModule } from 'primeng/multiselect';
import { UserService } from '../../../../../../../../shared/services/user.service';

@Component({
    selector: 'offer-confirmation-modal',
    providers: [DialogService],
    standalone: true,
    imports: [
        FloatLabelModule,
        DialogModule,
        ButtonModule,
        FormsModule,
        InputTextModule,
        CommonModule,
        DynamicDialogModule,
        DropdownModule,
        CalendarModule,
        InputSwitchModule,
        MultiSelectModule
    ],
    templateUrl: './talent-pool-modal.component.html',
})
export class TalentpoolModalComponent implements OnInit {

    talentPools: Array<TalentPool> = [];
    filteredPools: Array<TalentPool> = [];
    selectedPools: Array<TalentPool> = [];
    onChangeTalentPool: Function | null = null;
    sessionUser: any = null;

    constructor(
        public ref: DynamicDialogRef,
        public dialogService: DialogService,
        public config: DynamicDialogConfig,
        private userService: UserService
    ) { }

    ngOnInit() {
        this.sessionUser = this.userService.getSessionUser();
        this.talentPools = this.config?.data?.talentPools;
        this.filteredPools = [...this.talentPools];
        this.onChangeTalentPool = this.config?.data?.onChangeTalentPool;
    }

    closeDialog() {
        if (this.selectedPools.length) {
            this.ref.close({
                talentPools: this.talentPools
            });
            return;
        }
        this.ref.close();

    }

    onToggleChange(e: any){
        if(e.checked){
            this.selectedPools = [];
            this.filteredPools = this.talentPools.filter(pool => pool.createdBy === this.sessionUser._id);
        } else {
            this.selectedPools = [];
            this.filteredPools = [...this.talentPools];
        }
    }

    onChangeTanentPools(): void {
        if(this.onChangeTalentPool){
            this.onChangeTalentPool(this.selectedPools.map(pool => pool._id));
        }
    }

    getCommaSeparatedNamesOfSelectedPool(): string{
        return this.selectedPools.map(pool => pool.name).join(', ');
    }
}
