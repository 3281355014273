import { Component } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { TabViewModule } from 'primeng/tabview';

import { FloatLabelModule } from 'primeng/floatlabel';
import { PasswordModule } from 'primeng/password';
import { InputSwitch, InputSwitchModule } from 'primeng/inputswitch';
import { CalendarModule } from 'primeng/calendar';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { NotificationPreference, Reviewer } from '../../../store/reviewer/reviewer.interface';
import { StorageService } from '../../../shared/services/storage.service';
import { ReviewerService } from '../../../shared/services/reviewer.service';
import { getReviewer } from '../../../store/reviewer/reviewer.selector';
import { Store } from '@ngrx/store';
import { InputMaskModule } from 'primeng/inputmask';
import { HeadingComponent } from '../../../shared/components/heading/heading.component';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule, FormControl } from '@angular/forms';
import { ToastService } from '../../../shared/services/toast.service';
import moment from 'moment';
import { ConfirmationDialogService } from '../../../shared/services/confirmation-dialog.service';
import { AuthService } from '../../../shared/services/auth.service';
import { UserService } from '../../../shared/services/user.service';
import { State } from '../profile/general/general.model';
import { constants } from '../../../shared/constants/constants';
import { lastValueFrom } from 'rxjs';
import { ProfileInformationClass } from '../../../shared/components/profile-information/profile-information.component';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-reviewer-setting',
  standalone: true,
  imports: [CommonModule, InputSwitchModule, ReactiveFormsModule, ButtonModule,
     InputMaskModule, TabViewModule, FloatLabelModule, PasswordModule,
      CalendarModule, InputTextModule, DropdownModule, HeadingComponent],
  templateUrl: './reviewer-setting.component.html',
  styleUrl: './reviewer-setting.component.scss',
  providers:[DatePipe,InputSwitch]
})
export class ReviewerSettingComponent extends ProfileInformationClass{
  [x: string]: any;
  cities: any[] | undefined;
  reviewer!: Reviewer
  showEditPhoneForm = false;
  editPhoneForm!: FormGroup;
  verificationPhoneForm!: FormGroup;
  notificationSwitches!: FormGroup;
  unAvailableForm!: FormGroup;
  passwordForm!: FormGroup;
  _isShowVerificationPhoneForm = false;
  currentDate = new Date();
  minEndDate = new Date();
  maxEndDate = new Date();
  phone = { partOne: '', partTwo: '', partThree: '' };
  tempPhone = { partOne: '', partTwo: '', partThree: '' };
  isDateChange = false;
  notificationPref!: NotificationPreference;
  title = environment.title;
  constructor(
    private storageService: StorageService,
    private confirmationDialogService: ConfirmationDialogService, 
    private authService:AuthService,
    fb: FormBuilder, 
    reviewerService:ReviewerService, 
    toastService:ToastService, 
    userService: UserService){
      super(fb, reviewerService, toastService, userService);
      this.currentDate.setDate(this.currentDate.getDate() - 1);
      this.editPhoneForm = this.fb.group({
        partOne: ['', Validators.required],
        partTwo: ['', Validators.required],
        partThree: ['', Validators.required]
      });
      this.verificationPhoneForm = this.fb.group({
        verificationCode: ['', Validators.required]
      });
      this.notificationSwitches = this.fb.group({
        sms: [''],
        email: [''],
        notification: ['']

      });
      this.unAvailableForm = this.fb.group({
        unAvailableStatus:[''],
        startDate:[''],
        endDate:['']
      });
      this.passwordForm = this.fb.group({
        currentPassword: ['', Validators.required],
        newPassword: ['', [Validators.required, Validators.minLength(8)]],
        confirmPassword: ['', Validators.required]
      }, { validators: this.mustMatch('newPassword', 'confirmPassword') });
  }

 
  
  async ngOnInit() {
    this.initializeSettings();
    await super.init();
  }
  
  mustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors['mustMatch']) {
        return;
      }

      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }
  initializeSettings(){
    this.reviewerService.fetchReviewer().subscribe((reviewer:Reviewer)=>{
      this.reviewer = reviewer;
    
          this.notificationSwitches.setValue({
            sms:reviewer.notificationPreference.sms,
            email:reviewer.notificationPreference.email,
            notification:reviewer.notificationPreference.notification 
            
          });
          this.unAvailableForm.setValue({
            unAvailableStatus:reviewer.notificationPreference.unAvailableStatus,
            startDate:new Date(reviewer.notificationPreference.unAvailableStartDate),
            endDate:new Date(reviewer.notificationPreference.unAvailableEndDate)

          });
       
          this.notificationPref = this.preparePreference(reviewer.notificationPreference);
          this.formatPhoneNo();
          this.setEndDateFromStartDate();
          this.reviewerService.fetchReviewerAndUpdate();

          console.log();
      
    })
  }

  onPassSubmit(): void {
    if (this.passwordForm.invalid) {
      this.toastService.showError('Error, Required fields incorrect or missing');
      return;
    }

    const { currentPassword, newPassword, confirmPassword } = this.passwordForm.value;
    const refreshObject = {
      refreshToken: this.storageService.getCookieValueByKey('refreshToken'),
      expiryFor: 'web'
    };

    this.reviewerService.changePassword(currentPassword, newPassword, confirmPassword, refreshObject).subscribe(
      (response) => {
     
        this.storageService.setUserToken(response);  // Set user token logic here
        this.toastService.showSuccess('Password Changed Successfully.');
        this.passwordForm.reset(); // Initialization logic here
      },
      (error) => {
        this.toastService.showError('Error while requesting.');
      }
    );
  }

  getBool(flag:boolean|undefined): boolean{
    return Boolean(flag);
  }

  hasDateChange(status: boolean): void {
    this.isDateChange = status;
  }

  formatPhoneNo(): void {
    this.phone.partOne = this.reviewer.profile.contact.home.slice(0, 3);
    this.phone.partTwo = this.reviewer.profile.contact.home.slice(3, 6);
    this.phone.partThree = this.reviewer.profile.contact.home.slice(6, 10);
  }

  setEndDateFromStartDate(): void {
    const date = this.notificationPref.unAvailableStartDate || this.currentDate;
    this.minEndDate = new Date(date.getTime());
    this.maxEndDate = new Date(date.getTime());
    this.maxEndDate.setDate(date.getDate() + 29);
  }

  updatePreference(type: string): void {
    const body: any = {};
    body[type] = this.notificationSwitches.value[type as keyof NotificationPreference];
    this.notificationPref = this.preparePreferenceForm();
    // Your validation logic
    if (!this.notificationPref.email && !this.notificationPref.notification && !this.notificationPref.sms) {
      this.notificationPref[type as keyof NotificationPreference] = !this.notificationPref[type as keyof NotificationPreference];
      this.toastService.showError('You must enable at least one notification option...');
    } else if (type === 'sms' && !this.reviewer.profile.contact.isPhoneVerified && this.notificationPref.sms) {
      this.notificationPref.sms = false;
      this.toastService.showError('Please verify your number to enable SMS notification.');
    } else if (type === 'unAvailableStatus' && this.notificationPref[type] && (!this.notificationPref.unAvailableStartDate || !this.notificationPref.unAvailableEndDate)) {
      this.toastService.showError('Please add date range for unavailability.');
    } else {
      if (type === 'unAvailableStatus') {
        if (this.notificationPref[type as keyof NotificationPreference]) {
          body[type] = this.notificationPref[type];
          body.unAvailableStartDate = this.notificationPref.unAvailableStartDate.toISOString();
          body.unAvailableEndDate = this.notificationPref.unAvailableEndDate.toISOString();
          body.timezone = moment().utcOffset();
        } else {
          body[type] = false;
          this.notificationPref.unAvailableStartDate = null;
          this.notificationPref.unAvailableEndDate = null;
        }
      }
      this.reviewerService.updateNotificationPreference(body).subscribe(
        res => {
          this.toastService.showSuccess( res.message);
          this.initializeSettings();
        },
        err => {
          this.toastService.showError(err.data?.message || 'Error updating notification preferences.');
          this.notificationPref[type as keyof NotificationPreference] = !this.notificationPref[type as keyof NotificationPreference];
          if (type === 'unAvailableStatus') {
            this.notificationPref.unAvailableStartDate = null;
            this.notificationPref.unAvailableEndDate = null;
          }
        }
      );
    }
  }

  preparePreference(notificationPreference: NotificationPreference): any {
    let notObject = {...notificationPreference};
    notObject.unAvailableStartDate = notificationPreference.unAvailableStartDate ? new Date(notificationPreference.unAvailableStartDate) : null;
    notObject.unAvailableEndDate = notificationPreference.unAvailableEndDate ? new Date(notificationPreference.unAvailableEndDate) : null;
    return notObject;
  }
  preparePreferenceForm(): any {
    let notObject = {...this.notificationSwitches.value};
    notObject.unAvailableStatus = this.unAvailableForm.value.unAvailableStatus;
    notObject.unAvailableStartDate = this.unAvailableForm.value.startDate ? new Date(this.unAvailableForm.value.startDate) : null;
    notObject.unAvailableEndDate = this.unAvailableForm.value.endDate ? new Date(this.unAvailableForm.value.endDate ) : null;
    return notObject;
  }

  refreshPreferences(): void {
  //  this.reviewerService.fetchReviewerAndUpdate();
  }

  sendVerificationPhoneSms(): void {
    const phoneNo = this.tempPhone.partOne + this.tempPhone.partTwo + this.tempPhone.partThree;
    this.reviewerService.sendVerificationPhoneSms(phoneNo).subscribe(
      res => {
        this._isShowVerificationPhoneForm = true;
        this.toastService.showSuccess(res.data?.message || 'Verification code has been sent to your registered mobile number.');
      },
      err => {
        this.toastService.showError(err.data?.message || 'Error in phone number verification.');
      }
    );
  }

  editPhoneNo(): void {
    this.showEditPhoneForm = !this.showEditPhoneForm;
    this.editPhoneForm.setValue({
      partOne:this.reviewer.profile.contact.home.slice(0, 3),
      partTwo:this.reviewer.profile.contact.home.slice(3, 6),
      partThree:this.reviewer.profile.contact.home.slice(6, 10)
    });
    this.editPhoneForm.markAsPristine();
  }

  showVerificationPhoneForm(): void {
    this._isShowVerificationPhoneForm = !this._isShowVerificationPhoneForm;
    this.verificationPhoneForm.reset();
  }

  updatePhone(): void {
    if (this.editPhoneForm.valid) {
      const phoneNo = this.editPhoneForm.value.partOne + this.editPhoneForm.value.partTwo + this.editPhoneForm.value.partThree;
      this.reviewerService.updatePhoneNo({ phone: phoneNo }).subscribe(
        res => {
          this.toastService.showSuccess(res.data?.message || 'Phone number successfully updated.');
          this.editPhoneNo();
          this.initializeSettings();
          this.reviewerService.fetchReviewerAndUpdate();
        }
      );
    }
  }

  verifyPhone(): void {
    if (this.verificationPhoneForm.valid) {
      this.userService.verifyPhone({ code: this.verificationPhoneForm.value.verificationCode }).subscribe(
        res => {
          this.toastService.showSuccess(res.data?.message || 'Phone number successfully verified.');
          this.showVerificationPhoneForm();
          this.refreshPreferences();
        }
      );
    }
  }
  requestAccountDeletion() {
    this.confirmationDialogService.confirm({
    message: 'Are you sure that you want to delete Account?',
    header: 'Confirmation',
    icon: 'pi pi-exclamation-triangle',
    acceptIcon:"none",
    rejectIcon:"none",
    rejectButtonStyleClass:"p-button-text",
      accept: () => {
        
          this.reviewerService.requestForAccountDeletion().subscribe((response)=>{
            this.toastService.showSuccess('Request Initiated');
            this.authService.logout();
      });              
    }});
  }
    

  refreshProfileStrength(): void {
    // Your logic to refresh profile strength
  }
}
