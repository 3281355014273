<div>
      <div class="grid mt-2">
          <h5>
            Select Talent Pool
          </h5>
      </div>
      <div>
        <p-floatLabel class="h-full">
            SELECTED POOLS: 
            {{getCommaSeparatedNamesOfSelectedPool()}}
        </p-floatLabel>
      </div>
      <div>
        
        <p-inputSwitch inputId="switch1" (onChange)="onToggleChange($event)" />
        <label for="switch1">View My Talent Pools</label>
      </div>
      <div>
        <p-multiSelect 
            [options]="filteredPools" 
            [(ngModel)]="selectedPools" 
            optionLabel="name" 
            placeholder="Select talent pool"
            (onChange)="onChangeTanentPools()" />
      </div>
      <div class="flex justify-content-end">
  
        <p-button
          label="Done"
          size="large"
          [rounded]="true"
          [outlined]="true"
          (onClick)="closeDialog()"
          [ngStyle]="{'margin-right': '10px'}"      />
      </div>
  </div>
  