<div class="flex justify-content-between align-items-center gap-3 mb-4">
  <h4 class="m-0">Messaging</h4>
  <p-dropdown
    [options]="roles"
    optionLabel="roleType"
    inputId="selectRole"
    placeholder="Select Role"
    [(ngModel)]="selectedRole"
    styleClass="w-full lg:w-29rem"
    (onChange)="fetchMessagesByRole()"
  />
</div>

<div class="w-full grid grid-nogutter p-4 pr-0 pb-0 h-page-full bg-primary-shade-4p">
  
  <div *ngIf="!roles.length">
    No Positions Available
  </div>
  <div class="col-12 lg:col-4 pr-4 lg:flex flex-column" [ngClass]="{ 'hidden': !isMessageOpen }">
    <!-- Messages -->
    <div class="flex flex-column">
      <p-iconField *ngIf="discussions && discussions.length" iconPosition="right">
        <p-inputIcon styleClass="pi pi-search" />
        <input [(ngModel)]="subjectSearch" type="text" pInputText placeholder="Search" (input)="getFilteredDiscussion()" />
      </p-iconField>
      <div class="flex flex-column">
        <p-scrollPanel [style]="{ width: '100%', height: '65vh' }">
          <ul class="list-none">
            @for (msg of getFilteredDiscussion(); track $index) {
              <li [ngStyle]="{'background-color': msg.discusstionId === userInfo.currentSelectedDiscussion ? 'rgba(95, 72, 218, 0.08)' : ''}"
              class="flex gap-2 border-bottom-1 surface-border py-3 cursor-pointer"
              (click)="getThreadsByDiscussion(msg.discusstionId, msg.createdBy)">
                <div>
                  <p-avatar
                    icon="pi pi-user"
                    styleClass="mr-2"
                    size="large"
                    [style]="{
                      'background-color': '#EFF3FF80',
                      color: '#2a1261'
                    }"
                    shape="circle"
                  />
                </div>
                <div class="flex flex-column gap-2 w-full">
                  <div class="flex justify-content-between align-items-center font-bold">
                    <span class="flex">{{msg.subject}}</span>
        
                    <div class="flex">
                      
                      <span class="text-xs">{{getFormattedTime(msg.lastMessageDate)}}</span>
                    </div>
                  </div>
                  
                  <div class="flex flex-row text-black-alpha-80 justify-content-between">
                    <span class="flex text-sm">{{msg.lastMessage}}</span> 
                    @if(msg.reviewerUnreadMessageCount && msg.reviewerUnreadMessageCount > 0){
                      <p-badge class="flex right ml-3" value="{{msg.reviewerUnreadMessageCount}}"  severity="primary"></p-badge>
                    }
                  </div>
                </div>
              </li>
            }
          </ul>
        </p-scrollPanel>
      </div>
    </div>
    <!-- Messages End -->
  </div>
  <div class="col-12 lg:col bg-white lg:flex flex-column" [ngClass]="{ 'hidden': messages.length }">
    <!-- Messages Detail -->
    <div *ngIf="messages.length" class="pb-2 pl-4 bg-primary-shade-4p">
      <div class="flex flex-column">
        <span class="text-sm font-medium">{{messages[0].senderName}}</span>
        <span class="text-xs">Last contacted at {{messages[messages.length-1].msgDate | date : 'h:mm a'}}</span>
      </div>
    </div>

    <div class="px-4">
      <div *ngIf="discussions && !discussions.length && !messages.length && !fetchingDiscussions">
        No Message Available
      </div>
      <p-scrollPanel id="msg-list" [style]="{ width: '100%', height: '40vh' }">
        <ul class="list-none m-0">
          @for (msg of messages; track $index) {
            <li class="flex gap-2 border-bottom-1 surface-border py-3">
              <div>
                <p-avatar
                  icon="pi pi-user"
                  styleClass="mr-2"
                  size="large"
                  [style]="{ 'background-color': '#EFF3FF80', color: '#2a1261' }"
                  shape="circle"
                />
              </div>
              <div class="flex flex-column gap-2 w-full">
                <div
                  class="flex justify-content-between align-items-center font-bold"
                >
                  <span class="text-primary-900">{{msg.senderName}}</span>
                  <span class="text-xs">{{msg.msgDate | date : 'MMM d, y h:mm a'}}</span>
                </div>
                <div class="flex flex-column text-black-alpha-80">
                  <span class="text-sm">{{msg.content}}</span>
                </div>
              </div>
            </li>
          }
        </ul>
        @if(fetchingDiscussions){
          <ul class="list-none m-0 contact-list">            
            <li class="  px-4"  >
              <div class="text-center" >
                <p-progressSpinner [style]="{ width: '50px' }" ariaLabel="loading" />
              </div>
            </li>
          </ul>
        }
      </p-scrollPanel>
    </div>

    <div *ngIf="messages.length"
      class="p-4 border-top-1 surface-border mt-auto flex flex-column align-items-end gap-3"
    >
      <textarea
        rows="5"
        cols="30"
        [(ngModel)]="userInfo.content"
        placeholder="Type message here..."
        (keydown.enter)="onEnterPressed($event)"
        maxlength="1000"
        pInputTextarea
        [autoResize]="true"
      ></textarea>
      <p-button
        label="Send Message"
        rounded="true"
        [disabled]="!userInfo.content"
        styleClass="teal white-space-nowrap"
        (click)="sendMessage()"
      ></p-button>
    </div>
    <!-- Messages Detail -->
  </div>
</div>
