import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputTextModule } from 'primeng/inputtext';
import { CommonModule } from '@angular/common';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ActivatedRoute, RouterModule, Router } from '@angular/router';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { CalendarModule } from 'primeng/calendar';
import { TimeKeepingService } from '../../../../shared/services/time-keeping.service';
import { ToastService } from '../../../../shared/services/toast.service';
import moment from 'moment';
@Component({
  selector: 'app-timesheet-editor',
  standalone: true,
  imports: [
    CalendarModule,
    InputTextareaModule,
    InputSwitchModule,
    FloatLabelModule,
    DialogModule,
    ButtonModule,
    FormsModule,
    InputTextModule,
    CommonModule,
    ReactiveFormsModule,
    DropdownModule,
    RouterModule
  ],
  templateUrl: './timesheet-editor.component.html',
  styleUrl: './timesheet-editor.component.scss'
})
export class TimesheetEditorComponent implements OnInit {
  timesheetEditorForm!: FormGroup;
  @Input() selectedRole: any;
  @Input() roles: any;
  @Input() isEdit: any;
  @Input() data: any;
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();
  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private timeKeepingService: TimeKeepingService,
    private toast: ToastService,
  ) { }

  ngOnInit() {
    this.initializeForm();
  }
  initializeForm() {
    this.timesheetEditorForm = this.fb.group({
      notes: [this.isEdit ? this.data.notes : '', [Validators.required]],
      isCurrentlyWorking: [, false],
      selectedRole: [this.selectedRole],
      end_date: [this.isEdit ? new Date(this.data.end) : new Date()],
      start_date: [this.isEdit ? new Date(this.data.start) : new Date()],
      start_time: [this.isEdit ? new Date(this.data.start) : new Date()],
      end_time: [this.isEdit ? new Date(this.data.end) : new Date()],
    });
    /*  if(this.isEdit && this.timesheetEditorForm != null){
       this.timesheetEditorForm.get('isCurrentlyWorking').disable();
     } */
  }
  onSubmit() {
    const timeSheetData = this.timesheetEditorForm.value
    const startTime = this.timesheetEditorForm.value.start_time.toLocaleTimeString([], { hour12: false });
    const endTime = this.timesheetEditorForm.value.end_time.toLocaleTimeString([], { hour12: false });
    const st = startTime.split(':');
    const et = endTime.split(':');
    const start_date = timeSheetData.start_date;
    start_date.setHours(st[0]);
    start_date.setMinutes(st[1]);
    start_date.setSeconds(st[1]);
    const end_date = timeSheetData.end_date;
    end_date.setHours(et[0]);
    end_date.setMinutes(et[1]);
    end_date.setSeconds(et[1]);

    if (this.isEdit) {
      const body = {
        end_date: !timeSheetData.isCurrentlyWorking ? moment(end_date).set("seconds", 0).format() : '',
        start_date: !timeSheetData.isCurrentlyWorking ? moment(start_date).set("seconds", 0).format() : '',
        role_id: this.selectedRole.value,
        notes: timeSheetData.notes,
        timezone: moment().tz(moment.tz.guess()).format('z'),
        id: this.data._id,
        is_latest: this.data.isLatest,
      };
      this.timeKeepingService.updateTSheet(body).subscribe((d: any) => {
        this.toast.showSuccess('Time updated Successfully');
        this.cancel(true);
      })
    } else {
      const body = {
        end_date: !timeSheetData.isCurrentlyWorking ? moment(end_date).set("seconds", 0).format() : '',
        start_date: !timeSheetData.isCurrentlyWorking ? moment(start_date).set("seconds", 0).format() : '',
        role_id: this.selectedRole.value,
        notes: timeSheetData.notes,
        timezone: moment().tz(moment.tz.guess()).format('z')
      };
      this.timeKeepingService.addTSheet(body).subscribe((d: any) => {
        this.toast.showSuccess('Time added Successfully');
        this.cancel(true);
      })
    }

  }
  cancel(data: any) {
    this.closeModal.emit(data);
  }
}
