import { Component, OnInit } from '@angular/core';
import { ChartComponent } from '../../../shared/components/chart/chart.component'
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { ChipModule } from 'primeng/chip';
import { CardModule } from 'primeng/card';
import { PanelModule } from 'primeng/panel';
import { ProgressBarModule } from 'primeng/progressbar';
import { TabViewModule } from 'primeng/tabview';
import { DataViewModule } from 'primeng/dataview';
import { HeadingComponent } from '../../../shared/components/heading/heading.component'
import { ReviewerService } from '../../../shared/services/reviewer.service';
import { StorageService } from '../../../shared/services/storage.service';
import { Reviewer, Strength } from '../../../store/reviewer/reviewer.interface';
import { DividerModule } from 'primeng/divider';
import { getReviewer } from '../../../store/reviewer/reviewer.selector';
import { Store } from '@ngrx/store';
import { ToastService } from '../../../shared/services/toast.service';
import { ConfirmationDialogService } from '../../../shared/services/confirmation-dialog.service';
import { DialogModule } from 'primeng/dialog';
import { FloatLabelModule } from 'primeng/floatlabel';
import { Subscription, lastValueFrom } from 'rxjs';
import { StopwatchService } from '../../../shared/services/stopwatch.service';
import { TimeKeepingService } from '../../../shared/services/time-keeping.service';
import { ProjectService } from '../../../shared/services/project.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TrackingTimeModalComponent } from './tracking-time-modal/tracking-time-modal.component';
import { ConflictQuestionsModalComponent } from './conflict-questions-modal/conflict-questions-modal.component';
import moment, { Moment } from 'moment';
import { Response, Role, Roles, ToDoList, getTsheets } from '../reviewer-dashboard.interface';
import { RouterModule, Router } from '@angular/router';
import { EmittersService } from '../../../shared/services/emitters.service';
import { ViewDocusignDocumentsComponent } from './view-docusign-documents-modal/view-docusign-documents-modal.component';
import { DocumentService } from '../../../shared/services/document-service';
import { UserService } from '../../../shared/services/user.service';
import { UserClientVm } from '../../../store/user/user.interface';
import { ViewDocumentsComponent } from './view-documents-modal/view-documents-modal.component';
import { ProfileCompletionModalComponent } from './profile-completion-modal/profile-completion-modal.component';
import { constants } from '../../../shared/constants/constants';
import { RoleEndedModalComponent } from './role-ended-modal/role-ended-modal.component';
import { RateProjectComponent } from './rate-project-modal/rate-project-modal.component';
import { QuitPositionModalComponent } from './quit-position-modal/quit-position-modal.component';
import { RoleCompletedModalComponent } from './role-completed-modal/role-completed-modal.component';
import { OfferService } from '../../../shared/services/offers.service';
import { QuitConfirmationModalComponent } from './quit-confirmation-modal/quit-confirmation-modal.component';
import { BadgeModule } from 'primeng/badge';

@Component({
  selector: 'app-reviewer-dashboard',
  standalone: true,
  imports: [ChartComponent, ChipModule, FormsModule, ButtonModule, DropdownModule,
    HeadingComponent, CommonModule, CardModule, ProgressBarModule,
    PanelModule, TabViewModule, DataViewModule, DividerModule, DialogModule, FloatLabelModule, RouterModule, BadgeModule],
  providers: [DialogService],
  templateUrl: './reviewer-dashboard.component.html',
  styleUrl: './reviewer-dashboard.component.scss'
})
export class DashboardReviewerComponent implements OnInit {
  user: UserClientVm | null = null;
  intervalId: any;
  subscription!: Subscription;
  profileStrengthValue!: number;
  reviewer!: Reviewer;
  showMultiTenantFeature = true;
  positions: any = [];
  selectedFuturePosition: any;
  selectedCurrentPosition: any;
  offers: any;
  visibleAcceptConfirmationModal = false;
  visibleRejectConfirmationModal = false;
  offerRejectReasons = this.reviewerService.getReasons();
  selectedReason: { name: string, label: string } | null = null;
  ref: DynamicDialogRef | undefined;
  conflictQuestionRef: DynamicDialogRef | undefined;
  roles: any = [];
  dailySecs = 0;
  isClockedIn: boolean = false;
  clockedInTimeSheet: any;
  dailyDuration: any;
  selectedRoleForTimmer!: string;
  contractorTimekeepingEnabled!: boolean;
  invoiceSubmittedOn!: string;
  tsheets: getTsheets[] = [];
  graphLabels: string[] = [];
  graphData =  [
    {
      label: 'Graph',
      data: [],
      backgroundColor: ['rgba(255, 159, 64, 0.2)'],
      borderColor: ['rgb(255, 159, 64)'],
      borderWidth: 1,
    }
  ];
  totalDuration!: { hours: string, minutes: string, seconds: string };
  loading!: boolean ;
  weekSecs: number = 0;
  weekDuration!: { hours: string, minutes: string, seconds: string};
  totalSecs: number = 0;
  chartWidth = 400;
  chartHeight = 200;
  enableTimeTrackerGraph = true ;
  toDoList: ToDoList[] = [];
  isDocuSignDocumentsFetched = false;
  isDocuSignAdditionalEnvelopeDocumentsFetched = false;
  isDocumentRemaining = false;
  currentPositionEnvelop: any = null;
  currentPositionAdditionalEnvelopes: any = null;
  isUserProfileCompleted: boolean = false;
  onboarding!:  string;
  activeTabIndex = 0;

  constructor(
    private reviewerService: ReviewerService, 
    private store: Store, 
    private storageService: StorageService,
    private toastService: ToastService, 
    private confirmationDialogService: ConfirmationDialogService, 
    private dialogService: DialogService,
    private stopwatchService: StopwatchService, 
    private timeKeepingService: TimeKeepingService, 
    private projectService: ProjectService,
    private offerService: OfferService,
    private router:Router, 
    private emitterService:EmittersService, 
    private documentService: DocumentService,
    private userService: UserService
  ) {
      this.emitterService.tenantEmitter.subscribe(()=>{
        this.initialization();
      })
      this.onboarding = history.state.data?.from;
  }

  ngOnInit() {
    this.initialization();
  }


  getStopwatchSubscription() {
    this.subscription = this.stopwatchService.getStopwatchState().subscribe(state => {
      if (state === 'running') {
        this.updateTime();
      } else {
        this.stopTimer();
      }
    });
  }
  startStopwatch(): void {
    if(this.stopwatchService.intervalId){
      return
    }   
    this.stopwatchService.startTime();
  }

  startTimer(): void {
    this.stopwatchService.intervalId = setInterval(() => {
      this.updateTime();
    }, 1000);
  }

  stopTimer(): void {
    if(this.stopwatchService.intervalId === null){
      return
    }
    this.stopwatchService.stopTime();
  }

  async initialization(): Promise<void> {
    this.user = this.userService.getSessionUser();
    this.store.select(getReviewer).subscribe((reviewer: Reviewer) => {
      this.reviewer = reviewer;
    });
    this.selectedCurrentPosition = null;
    this.selectedFuturePosition = null;
    this.checkProfileCompletion();
    await this.getPositions();
    await this.getOffers();
    
    this.getStopwatchSubscription();
    await this.getTsheetList(this.getCurrentWeek());
    await this.getToDoList();
    this.setDefaultTabForRedirection();
    this.getUserProfileStrength();
  }

  setDefaultTabForRedirection(): void{
    if(this.toDoList.length){
      this.activeTabIndex = 0;
      return;
    }
    if(this.selectedCurrentPosition){
      this.activeTabIndex = 1;
      return;
    }
    this.activeTabIndex = 0;
  }

  quitPosition(position: any) {
    const quitConfirmationModal = this.dialogService.open(QuitConfirmationModalComponent,{
      header: 'End Role',
      width: '50vw',
      contentStyle: { overflow: 'hidden' },
      breakpoints: {
        '960px': '75vw',
        '640px': '90vw'
      }
    });
    quitConfirmationModal.onClose.subscribe(async (data) => {
      if(data.isConfirm){
        this.reviewerService.quitPosition(position._id, data.reason).subscribe(() => {
          this.toastService.showSuccess("Position Quit Successfully.");
          const quitPositionModal = this.dialogService.open(QuitPositionModalComponent, {
            data: {
              position,
              project: position.project,
              role: position.role
            },
            header: 'Role Ended',
            closable: false,
            width: '50vw',
            contentStyle: { overflow: 'hidden' },
            breakpoints: {
              '960px': '75vw',
              '640px': '90vw'
            }
          });
      
          quitPositionModal.onClose.subscribe(async (data: any) => {
            if (data) {
              this.openRatingModal({
                project: position.project,
                role: position.role,
                firm: position.firm
              });
            } else {
              this.ngOnInit();
              this.onViewTimeSheet(position, true);
            }
          });
        });
      }
    })
  }

  openRatingModal(ratingInfo: any, shouldCallAcknowledgeEndedPosition = false){
    const ratingModal = this.dialogService.open(RateProjectComponent, {
      data: ratingInfo,
      header: 'Rate Project',
      width: '50vw',
      breakpoints: {
        '960px': '75vw',
        '640px': '90vw'
      }
    });

    ratingModal.onClose.subscribe(async (ratingResponse: any) => {
      if(ratingResponse.isConfirm){
        const ratingPayload = {
          project: ratingInfo.project._id,
          author: this.user?._id,
          stars: ratingResponse.rating
        }
        await this.projectService.createProjectRating(ratingInfo.firm._id, ratingPayload);
        this.updateTodoListItems('Role Ended');
        this.toastService.showSuccess('Rated successfully.');
        if(shouldCallAcknowledgeEndedPosition){
          await this.flowCompleted(ratingInfo.position);
        }
      }
      this.ngOnInit();
    });
  }

  showRoleEndedModal(position: any): void{
    const roleEndModal = this.dialogService.open<any>(position.projectStarted ? RoleCompletedModalComponent : RoleEndedModalComponent, {
      data: {
        position,
        project: position.project,
        role: position.role
      },
      header: !position.presumptive || (position.presumptive && position.presumptive.status !== constants.presumptiveStatus.DENIED) ? 'Invoicing and Timekeeping' : '',
      closable: false,
      width: '50vw',
      contentStyle: { overflow: 'hidden' },
      breakpoints: {
        '960px': '75vw',
        '640px': '90vw'
      }
    });

    roleEndModal.onClose.subscribe(async (data: any) => {
      
      if(position.projectStarted){
        if(!data){
          this.onViewTimeSheet(position, true);
          return;
        }

        if (position.presumptive && position.presumptive.status === constants.presumptiveStatus.DENIED) {
            return await this.flowCompleted(position);
        } else {
            this.openRatingModal({
              position,
              project: position.project,
              role: position.role,
              firm: position.firm
            }, true);
            return;
        }

      } else {
        if(!data){
          return;
        }
        return await this.flowCompleted(position);
      }
    });
  }

  async flowCompleted(position: any): Promise<void>{
    await this.offerService.acknowledgeEndedPosition(position._id);
    let message = 'Project ended successfully';
    if(position.presumptive && position.presumptive.status === constants.presumptiveStatus.DENIED){
        message = 'Removed Succesfully';
    }
    this.toastService.showSuccess(message);
    this.ngOnInit();
  }

  async getUserProfileStrength() {
    this.profileStrengthValue = 0;
    
    if (this.showMultiTenantFeature) {
      let currentFirm = this.storageService.getTenant();
      if (currentFirm) {
        this.profileStrengthValue = await this.reviewerService.setProfileStrengthForStaftr(this.reviewerService.getReviewer());
      }
    }
  }
  async getOffers() {
    this.offers = await lastValueFrom(this.reviewerService.getOffers());
  }
  
  async getPositions() {
    const res = await lastValueFrom(this.reviewerService.getPositions());
    if(res.ended && res.ended.length){
      res.ended.map((endedPosition: any) => {
        endedPosition.name = endedPosition.project.codeName + '--' + endedPosition.role.roleType;
      })
      this.showRoleEndedModal(res.ended[0]); 
    }
    this.positions = await Promise.all(res.occupied.map(async (position: any) => {
      const myCurrentDocument = await this.documentService.getMyCurrentDocuments(position.project._id);
      position.docs = myCurrentDocument ? myCurrentDocument : {};
      position.name = position.project.codeName + '--' + position.role.roleType;
      return position;
    }));
    this.getTotalProjectDuration();
  }

  getFuturePositions() {
    return this.positions.filter((p: any) => p.isFuture);
  }

  getCurrentPositions() {
    return this.positions.filter((p: any) => !p.isFuture);
  }

  loadCurrentPosition(){
    if(this.selectedCurrentPosition){
      this.getToDoList();
      if (!this.selectedCurrentPosition.docs && this.selectedCurrentPosition.project.documents.length > 0) {
        this.getDocs();
      } else {
          this.checkRequiredDocumentsOnFlag();
      }  
    }
  }

  loadFuturePosition(){
    if(this.selectedFuturePosition){
      if (!this.selectedFuturePosition.docs && this.selectedFuturePosition.project.documents.length > 0) {
        this.getFutureDocs();
      } else {
          this.checkRequiredDocumentsOnFlag();
      }  
    }
  }

  async getDocs(){
    const documents = await this.documentService.getMyCurrentDocuments(this.selectedCurrentPosition.project._id);
    this.selectedCurrentPosition.docs = documents;
  }

  async getFutureDocs(){
    const documents = await this.documentService.getMyCurrentDocuments(this.selectedFuturePosition.project._id);
    this.selectedFuturePosition.docs = documents;
  }

  async checkRequiredDocumentsOnFlag(){
    if(this.selectedCurrentPosition.firm.isDocusignEnabled && this.selectedCurrentPosition.docusignEnabled) {
      await this.checkRequiredDocuSignDocumentsUploaded(this.selectedCurrentPosition);
    } else{
      this.checkRequiredDocumentsUploaded(this.selectedCurrentPosition);
    }
  }

  async checkRequiredFutureDocumentsOnFlag(){
    if(this.selectedFuturePosition.firm.isDocusignEnabled && this.selectedFuturePosition.docusignEnabled) {
      await this.checkRequiredDocuSignDocumentsUploaded(this.selectedFuturePosition);
    } else{
      this.checkRequiredDocumentsUploaded(this.selectedFuturePosition);
    }
  }

  markAvailable() {
    this.reviewerService.updateMarkedAvailability().subscribe((resp) => {
      this.reviewerService.fetchReviewerAndUpdate();
      this.toastService.showSuccess("Your availability has been noted and conveyed to Mplace Staffing Partners.");
    });
  }

  openAcceptConfirmationModal(): void {
    this.visibleAcceptConfirmationModal = true;
  }

  openRejectionConfirmationModal(): void {
    this.visibleRejectConfirmationModal = true;
  }

  async accept($event: any, confirm = false) {
    this.visibleAcceptConfirmationModal = false;
    if (!confirm) {
      return;
    }
    if (this.offers.role && this.offers.role.hasConflict && this.offers.role.conflictQuestions.length > 0) {
      this.conflictQuestionsModal($event)
    } else {
      await this.offerAccept();
    }
  }

  async offerReject(confirm = false): Promise<void> {
    if (!confirm) {
      this.visibleRejectConfirmationModal = false;
      return;
    }
    if (!this.selectedReason) {
      this.toastService.showError('Please select the reason.');
      return;
    }
    this.visibleRejectConfirmationModal = false;
    await this.reviewerService.rejectOfferWithReason(this.offers?._id,{reason: this.selectedReason.label});
    this.updateTodoListItems('Project Offer');
    this.initialization();
  }

  async offerAccept() {
    await this.reviewerService.acceptOffer(this.offers?._id);
    this.updateTodoListItems('Project Offer');
    this.initialization();
  }

  async conflictQuestionsModal($event: any) {
    const data = {
      conflictQuestions: this.offers.role.conflictQuestions
    }
    $event.stopPropagation();
    this.conflictQuestionRef = this.dialogService.open(ConflictQuestionsModalComponent, {
      data: data,
      header: 'Screening Question',
      width: '30vw',
      contentStyle: { overflow: 'hidden' },
      breakpoints: {
        '960px': '75vw',
        '640px': '90vw'
      }
    });

    this.conflictQuestionRef.onClose.subscribe(async (data: any) => {
      if (data) {
        if (data.isOfferAccepted) {
          await this.offerAccept();
        } else {
          let rejectedDetails: any = {
            'reason': 'Conflicted Out'
          };
          if (data.conflictReasons) {
            rejectedDetails['conflictReasons'] = data.conflictReasons;
          }
          this.toastService.showError('Offer has been rejected due to wrong answer of this question: '+ JSON.parse(data.conflictReasons.question).title);
          await this.reviewerService.rejectOfferWithReason(this.offers?._id, rejectedDetails);
          this.updateTodoListItems('Project Offer');
          this.initialization();
        }
      }
    });
  }

  getTotalProjectDuration() {
    const currentPosition = this.getCurrentPositions();
    if (currentPosition.length > 0) {
      const position = currentPosition[0];
      const navigatedWeek = this.getCurrentWeek();
      let payload: any = { role_id: position.role.id, project_id: position.project._id };
      if (navigatedWeek && navigatedWeek.startDate) {
        payload.dateRange = navigatedWeek;
        payload.inclusive = true;
        payload.position = position._id.toString();
        payload.weekId = moment(navigatedWeek.startDate).add(2, 'days').isoWeek();
        this.timeKeepingService.getTsheets(payload).subscribe((res: any) => {
          if (res && res.stats && res.stats.totalHoursOnProject) {
            this.calcDailyDuration(res.daily);
            this.getRoles(position.project._id);
          }
        })
      }
    }
  }

  getCurrentWeek() {
    let startOfWeek = moment().isoWeekday(1);
    startOfWeek = startOfWeek.startOf('day');
    let endOfWeek = moment(startOfWeek).add(6, 'days');
    endOfWeek = endOfWeek.endOf('day');
    const week = { startDate: startOfWeek, endDate: endOfWeek };
    return week;
  }

  getRoles(projectId: string) {
    this.projectService.getRolesByReviewerProject([projectId]).subscribe((resp: Roles) => {
      this.roles = [];
      resp.map((role: Role) => {
        const data: any = {
          name: `${role.projectName}-${role.roleType}`,
          value: role._id
        }
        this.roles.push(data)
      })
    })
  }

  calcDailyDuration(tsheets: getTsheets[]){
    this.dailySecs = 0;
    tsheets?.forEach((tsheet: any) => {
      if (tsheet.onTheClock) {
        this.isClockedIn = true;
        this.clockedInTimeSheet = tsheet.id;
        this.selectedRoleForTimmer= tsheet.role._id;
        this.enableTick();
      }
      this.dailySecs += tsheet.duration;
    });
    this.dailyDuration = this.timeKeepingService.secondsToHMS(this.dailySecs);
  }

  enableTick() {
    this.startStopwatch();
    this.updateTime();
  }

  updateTime() {
    this.dailySecs++;
    this.dailyDuration = this.timeKeepingService.secondsToHMS(this.dailySecs);
  }

  disableTick() {
    this.stopTimer();
  }

  clockIn(roleId: any) {
    this.selectedRoleForTimmer = roleId;
    this.timeKeepingService.addTSheet({ role_id: roleId }).subscribe((res: any) => {
      this.isClockedIn = true;
      this.clockedInTimeSheet = res.id;
      this.enableTick();
    })
  }

  clockOut() {
    this.timeKeepingService.updateTSheet({ id: this.clockedInTimeSheet, clock_out: true, role_id: this.selectedRoleForTimmer, timezone: moment().format('Z') })
      .subscribe((res: any) => {
        this.disableTick();
        this.isClockedIn = false;
        this.clockedInTimeSheet = null;
        //this.toast.showSuccess('You hours have been tracked successfully.');
      })
  }

  showRolesModal($event: any) {
    const data = {
      roles: this.roles
    }
    $event.stopPropagation();
    this.ref = this.dialogService.open(TrackingTimeModalComponent, {
      data: data,
      header: 'Role Selection',
      width: '30vw',
      contentStyle: { overflow: 'hidden' },
      breakpoints: {
        '960px': '75vw',
        '640px': '90vw'
      }
    });

    this.ref.onClose.subscribe((data: any) => {

      if (data) {
        this.clockIn(data.value)
      }

    });
  }

  openViewDocumentsModal(): void{
    if(this.selectedCurrentPosition.firm.isDocusignEnabled && this.selectedCurrentPosition.docusignEnabled ){
      this.openDocuSignDocModal();
    } else {
      this.showDocumentsModal(); 
    }
  }

  openDocuSignDocModal(): void{

    this.ref = this.dialogService.open(ViewDocusignDocumentsComponent, {
      data: {
        documents: this.currentPositionEnvelop.documents, 
        envelopeId: this.currentPositionEnvelop.envelopeId,
        status: this.currentPositionEnvelop.status,
        additionalEnvelopes: this.currentPositionAdditionalEnvelopes,
        isReviewerModal: true
      },
      header: 'Documents',
      width: '70vw',
      contentStyle: { overflow: 'hidden' },
      breakpoints: {
        '960px': '75vw',
        '640px': '90vw'
      }
    });
  }

  showDocumentsModal() {
    this.ref = this.dialogService.open(ViewDocumentsComponent, {
      data: {
        position: this.selectedCurrentPosition
      },
      header: 'Documents',
      width: '70vw',
      contentStyle: { overflow: 'hidden' },
      breakpoints: {
        '960px': '75vw',
        '640px': '90vw'
      }
    });
  }

  async checkRequiredDocuSignDocumentsUploaded(position: any){
    if(!this.isDocuSignDocumentsFetched) {
      const roleId = position.role.id;
      const projectId = position.project.id;
      const positionId = position._id;
      const reviewerId = position.reviewer;
      const additionalEnvelopes = true;

      try {
        const response = await this.documentService.getEnvelopeDocumentsListing(projectId, roleId, reviewerId, positionId, false, false, false);
        if(response){
          this.isDocuSignDocumentsFetched = true;
          this.isDocumentRemaining = response.status !== 'completed';
          this.currentPositionEnvelop = response; 
        }
        const responseOfAllEnvelopes = await this.documentService.getEnvelopeDocumentsListing(projectId, roleId, reviewerId, positionId, false, additionalEnvelopes, false);
        if(responseOfAllEnvelopes){
          this.isDocuSignAdditionalEnvelopeDocumentsFetched = true;
          this.isDocumentRemaining = !!(responseOfAllEnvelopes.find((ev: any) => ev.status !== 'completed' ));
          this.currentPositionAdditionalEnvelopes = responseOfAllEnvelopes; 
        }
      } catch (error: any) {
        this.isDocuSignDocumentsFetched = true;
        this.isDocuSignAdditionalEnvelopeDocumentsFetched = true;
        this.toastService.showError((error && error.message ) || 'Error occured');
      }
    
    }
  }

  // need to change envelop as per the future docs
  async checkRequiredFutureDocuSignDocumentsUploaded(position: any){
    if(!this.isDocuSignDocumentsFetched) {
      const roleId = position.role.id;
      const projectId = position.project.id;
      const positionId = position._id;
      const reviewerId = position.reviewer;
      const additionalEnvelopes = true;

      try {
        const response = await this.documentService.getEnvelopeDocumentsListing(projectId, roleId, reviewerId, positionId, false, false, false);
        if(response){
          this.isDocuSignDocumentsFetched = true;
          this.isDocumentRemaining = response.status !== 'completed';
          this.currentPositionEnvelop = response; 
        }
        const responseOfAllEnvelopes = await this.documentService.getEnvelopeDocumentsListing(projectId, roleId, reviewerId, positionId, false, additionalEnvelopes, false);
        if(responseOfAllEnvelopes){
          this.isDocuSignAdditionalEnvelopeDocumentsFetched = true;
          this.isDocumentRemaining = !!(responseOfAllEnvelopes.find((ev: any) => ev.status !== 'completed' ));
          this.currentPositionAdditionalEnvelopes = responseOfAllEnvelopes; 
        }
      } catch (error: any) {
        this.isDocuSignDocumentsFetched = true;
        this.isDocuSignAdditionalEnvelopeDocumentsFetched = true;
        this.toastService.showError((error && error.message ) || 'Error occured');
      }
    
    }
  }

  checkRequiredDocumentsUploaded(pos: any) {
    this.isDocuSignDocumentsFetched = false;
    if (pos.docs && pos.docs.blank) {
      this.isDocuSignDocumentsFetched = true;
        const obj = pos.docs.blank;
        Object.keys(obj).forEach((key) => {
            if (!pos.docs.filled[this.user?._id || ''] || !pos.docs.filled[this.user?._id || ''][key]) {
                pos.isDocumentRemaining = true;
                if (!this.isDocumentRemaining) {
                    this.isDocumentRemaining = true;
                }
                return;
            } else {
                this.isDocumentRemaining = false;
            }
        });
    }
  }

  goToTimekeeping(){
    this.router.navigateByUrl('/reviewer/timekeeping');
  }
  
  private removeDuplicatesById(tsheets: any[]): any[] {
    const uniqueTsheets = tsheets.reduce((acc, tsheet) => {
      acc[tsheet._id] = tsheet;
      return acc;
    }, {});

    return Object.values(uniqueTsheets);
  }

  getWeeklytsheets(res: Response): getTsheets[] {
    const lastInvoice = res?.lastInvoice;
    let tsheets = res?.list;

    if (lastInvoice && lastInvoice.timesheets && lastInvoice.timesheets.length) {
      tsheets = [...res?.list, ...lastInvoice.timesheets];
      tsheets = this.removeDuplicatesById(tsheets);
    }

    tsheets = tsheets.filter((ts:any) => !ts.invoice || (lastInvoice && ts.invoice === lastInvoice._id));

    return tsheets;
  }

  calcTotalDuration(tsheets: getTsheets[]){
    this.totalSecs = 0;
    tsheets = tsheets || [];

    tsheets.forEach((tsheet) => {
      this.totalSecs += tsheet.duration;
    });

  }

  calcWeekDuration(tsheets: getTsheets[]) {
    this.weekSecs = 0;
    tsheets?.forEach((tsheet) => {
      if (tsheet.onTheClock) {
        this.clockedInTimeSheet = tsheet.id;
      }
      this.weekSecs += tsheet.duration;
    });

    this.weekDuration = this.timeKeepingService.secondsToHMS(this.weekSecs);
  }
  
  getPreviousWeek(navigatedWeek: { startDate: moment.Moment, endDate: moment.Moment }): { startDate: moment.Moment, endDate: moment.Moment } {
    return {
      startDate: navigatedWeek.startDate.clone().subtract(7, 'days'),
      endDate: navigatedWeek.endDate.clone().subtract(7, 'days')
    };
  }

  async getTsheetList(navigatedWeek: {startDate: Moment, endDate: Moment}, change?: any,  msg?: any): Promise<void> {
    const position: any = this.getCurrentPositions();

    if (position.length == 0) { return; }

    this.selectedCurrentPosition = change || position[0];
    const currentPosition = this.selectedCurrentPosition;

    let payload: any = { role_id: currentPosition.role._id };
    this.contractorTimekeepingEnabled = currentPosition.project.contractorTimekeepingEnabled;
    this.isDocuSignDocumentsFetched = false;

    if (navigatedWeek && navigatedWeek.startDate) {
      payload.dateRange = navigatedWeek;
      payload.inclusive = true;
      payload.position = currentPosition ? currentPosition._id.toString() : "";
      payload.weekId = moment(navigatedWeek.startDate).add(2, 'days').isoWeek();
    }

    const promises = [
      await lastValueFrom(this.timeKeepingService.getTsheets(payload))
    ];

    payload.weekId = payload.weekId - 1;
    payload.dateRange = this.getPreviousWeek(navigatedWeek);
    promises.push(await lastValueFrom(this.timeKeepingService.getTsheets(payload)));
    const responses = promises;
    
    const res = responses[0];
    if (this.contractorTimekeepingEnabled) {
      this.tsheets = this.getWeeklytsheets(res);
      this.tsheets = this.tsheets.concat(this.getWeeklytsheets(responses[1]));
      
      this.calcWeekDuration(this.tsheets);
      this.calcTotalDuration(this.tsheets);
    } else {
      this.tsheets = this.getWeeklytsheets(res);
      this.tsheets = this.tsheets.concat(this.getWeeklytsheets(responses[1]));
      this.calcWeekDuration(this.tsheets);
      this.calcTotalDuration(this.tsheets);
    }

    this.calcDailyDuration(res.daily);

    const combinedWeeks = { ...responses[1].stats.weeklyDistributedHours, ...res.stats.weeklyDistributedHours };
    this.graphLabels = Object.keys(combinedWeeks).map(function (k) {
      let key = k;
      let x = key.split('/');
      return x[0] + '/' + x[1];
    });

    this.graphData[0].data = [];
    const graphDataPoints: any = [];
    Object.keys(combinedWeeks).forEach(key => {
      graphDataPoints.push(combinedWeeks[key].dailyTotalHours);
    });
    this.graphData[0].data = graphDataPoints;
    this.totalDuration = res.stats.totalHoursOnProject;
    this.loading = false;
    this.loadCurrentPosition();
  }

  async onChangeFuturePosition(navigatedWeek: {startDate: Moment, endDate: Moment}, change?: any,  msg?: any): Promise<void> {
    
    // commented because of we have to continue this function in next sprint
    
    // const position: any = this.getFuturePositions();

    // if (position.length == 0) { return; }

    // this.selectedFuturePosition = change ? change: position[0];
    // const futurePosition = this.selectedFuturePosition;

    // let payload: any = { role_id: futurePosition.role._id };
    // this.contractorTimekeepingEnabled = futurePosition.project.contractorTimekeepingEnabled;
    // this.isDocuSignDocumentsFetched = false;

    // if (navigatedWeek && navigatedWeek.startDate) {
    //   payload.dateRange = navigatedWeek;
    //   payload.inclusive = true;
    //   payload.position = futurePosition ? futurePosition._id.toString() : "";
    //   payload.weekId = moment(navigatedWeek.startDate).add(2, 'days').isoWeek();
    // }

    // const promises = [
    //   await lastValueFrom(this.timeKeepingService.getTsheets(payload))
    // ];

    // payload.weekId = payload.weekId - 1;
    // payload.dateRange = this.getPreviousWeek(navigatedWeek);
    // promises.push(await lastValueFrom(this.timeKeepingService.getTsheets(payload)));
    // const responses = promises;
    
    // const res = responses[0];
    // if (this.contractorTimekeepingEnabled) {
    //   this.tsheets = this.getWeeklytsheets(res);
    //   this.tsheets = this.tsheets.concat(this.getWeeklytsheets(responses[1]));
      
    //   this.calcWeekDuration(this.tsheets);
    //   this.calcTotalDuration(this.tsheets);
    // } else {
    //   this.tsheets = this.getWeeklytsheets(res);
    //   this.tsheets = this.tsheets.concat(this.getWeeklytsheets(responses[1]));
    //   this.calcWeekDuration(this.tsheets);
    //   this.calcTotalDuration(this.tsheets);
    // }

    // this.calcDailyDuration(res.daily);

    // const combinedWeeks = { ...responses[1].stats.weeklyDistributedHours, ...res.stats.weeklyDistributedHours };
    // this.graphLabels = Object.keys(combinedWeeks).map(function (k) {
    //   let key = k;
    //   let x = key.split('/');
    //   return x[0] + '/' + x[1];
    // });

    // this.graphData[0].data = [];
    // const graphDataPoints: any = [];
    // Object.keys(combinedWeeks).forEach(key => {
    //   graphDataPoints.push(combinedWeeks[key].dailyTotalHours);
    // });
    // this.graphData[0].data = graphDataPoints;
    // this.totalDuration = res.stats.totalHoursOnProject;
    // this.loading = false;
    // this.loadFuturePosition();
  }

  async getToDoList(): Promise<void>{

    this.toDoList = [];

    if(!this.isUserProfileCompleted){
      this.toDoList.push(
        {
          _id: '0',
          header: 'Welcome to Staftr',
          body: 'Welcome to Staftr. To best position yourself to receive job offers please make sure to complete your Profile.\n It’s also recommended you download the Staftr mobile app and set your preferred notification settings',
          actions: [
            {
              label: 'Edit Profile',
              link: () => {
                this.router.navigateByUrl('reviewer/profile/main');
              }
            }, 
            {
              label: 'ACCOUNT SETTINGS',
              link: () => {
                this.router.navigateByUrl('reviewer/settings');
              }
            }
          ]
        }
      );      
    }

    this.toDoList.push(...await lastValueFrom(this.reviewerService.getToDoList(this?.selectedCurrentPosition?.role?._id || '')));

    this.toDoList.forEach((list: ToDoList) => {

      if(list.actions.length){
        list.actions.forEach((action) => {
          if(action.label === 'View Documents'){
            action.link = () => {
              this.updateTodoListItems('Documents');
              this.openViewDocumentsModal();
            }
          }
          else if (action.label === 'View Message') {
            action.link = () => {
              this.updateTodoListItems('Message Received');
              this.router.navigateByUrl('reviewer/messages');
            }
          }
          else if (action.label === 'Verify Time Records') {
            action.link = () => {
              this.onViewTimeSheet(this.selectedCurrentPosition);
            }
          }
          else if(action.label === 'Rate Project') {
            action.link = () => {
              this.getPositions();
            }
          }
        })
      }
    });
  }

  onViewTimeSheet(selectedCurrentPosition: any, displayApproveTime = false){
    const data = {
      name: selectedCurrentPosition.name,
      value: selectedCurrentPosition.role.id,
      displayApproveTime
    }
    this.router.navigateByUrl('reviewer/timekeeping',
      {
        state: {
          data: data
        },
      }
    );
  }

  checkProfileCompletion(){
    let profileStrength = this.reviewerService.getSelectedTenant().strength;
    delete profileStrength.updatedAt;
    this.isUserProfileCompleted = Object.values(profileStrength).every((value) => value === true);
    if(!this.isUserProfileCompleted && this.onboarding === constants.onboarding){
      this.ref = this.dialogService.open(ProfileCompletionModalComponent, {
        header: 'Welcome to Staftr',
        width: '30vw',
        contentStyle: { overflow: 'hidden' },
        breakpoints: {
          '960px': '75vw',
          '640px': '90vw'
        },
      });
    }
  }

  updateTodoListItems(type: string){
    let obj = this.toDoList.find((e) => e.header.includes(type));
    if(obj){
      this.reviewerService.updateNotificationHistory(obj._id, 'complete').subscribe(() => {
        this.getToDoList();
      });  
    }
  }
}
