import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogModule , DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { FormsModule } from '@angular/forms';
import { ChipModule } from 'primeng/chip';
import { IconFieldModule } from 'primeng/iconfield';
import { InputTextModule } from 'primeng/inputtext';
import { InputIconModule } from 'primeng/inputicon';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { HeadingComponent } from '../../../../../shared/components/heading/heading.component';
import { ProjectService } from '../../../../../shared/services/project.service';
import { CommonModule } from '@angular/common';
import { UserService } from '../../../../../shared/services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ArchivePayload, Project, ProjectQueryParams, ProjectTypes } from '../project.interface';
import { ArchiveConfirmation } from './modals/archive/archive-confirmation.component';
import { ToastService } from '../../../../../shared/services/toast.service';
import { ConfirmationDialogService } from '../../../../../shared/services/confirmation-dialog.service';
import { RateFreelancersComponent } from './modals/rate-freelancers/rate-freelancers.component';
import { TooltipModule } from 'primeng/tooltip';
import { UnarchiveProjectComponent } from './modals/unarchive/unarchive-project/unarchive-project.component';

@Component({
  selector: 'app-project-list',
  standalone: true,
  imports: [
    DropdownModule,
    ButtonModule,
    FormsModule,
    ChipModule,
    IconFieldModule,
    InputIconModule,
    HeadingComponent,
    InputTextModule,
    TableModule,
    CommonModule,
    DynamicDialogModule,
    ConfirmPopupModule,
    RateFreelancersComponent,
    TooltipModule,
  ],
  templateUrl: './project-listing.component.html',
  styleUrl: './project-listing.component.scss',
  providers: [DialogService],
  encapsulation: ViewEncapsulation.None,
})
export class ProjectListComponent implements OnInit {
  constructor(
    private projectService: ProjectService,
    private userService: UserService,
    private routeParams: ActivatedRoute,
    private router: Router,
    public dialogService: DialogService,
    private toastService: ToastService,
    private confirmationDialogService: ConfirmationDialogService
  ) {}
  cities: any;
  selectedCity: any;
  projects: any;
  projectsCount: number = 0;
  projectType!: string;
  projectsTypeTitle!: string;
  searchText!: string;
  searchedItems: string[] = [];
  projectTypes: ProjectTypes = {
    PUBLISHED: 'PUBLISHED',
    UNPUBLISHED: 'CREATED',
    ARCHIVED: 'ARCHIVED',
    STARTED: 'STARTED',
  };

  headingButton: any = {
    url: '/client/project/create',
    label: 'CREATE PROJECT',
  };
  projectOwner!: string;
  ngOnInit() {
    this.searchedItems = [];
    //this.headingButton.url = this.routeParams.snapshot.url
    this.routeParams.url.subscribe((value) => {
      this.projectType = value[0].path.toUpperCase();
      this.projectsTypeTitle =
        this.projectType[0].toUpperCase() +
        this.projectType.substring(1).toLowerCase() +
        ' Projects';
    });
  }

  ref: DynamicDialogRef | undefined;

  showArchiveConfirmation($event: Event, project: any) {
    $event.stopPropagation();
    this.ref = this.dialogService.open(ArchiveConfirmation, {
      header: 'Confirmation',
      width: '30vw',
      contentStyle: { overflow: 'auto' },
      breakpoints: {
        '960px': '75vw',
        '640px': '90vw',
      },
    });

    this.ref.onClose.subscribe((data: ArchivePayload) => {
      if (data) {
        this.projectService
          .projectArchive(project._id, data)
          .subscribe((response) => {
            this.getProjects({ first: 0, rows: 10 });
          });
      }
    });
  }

  ngOnDestroy() {
    if (this.ref) {
      this.ref.close();
    }
  }

  clearProjectSearch() {
    this.searchedItems = [];
    this.searchText = '';
    this.getProjects({ first: 0, rows: 10 });
  }
  onEnter($event: any) {
    if ($event.keyCode === 13) {
      this.getProjects({ first: 0, rows: 10 });
    }
  }
  getProjects(event?: any) {
    const user = this.userService.getSessionUser();
    let offset = event.first;
    let limit = event.rows;
    const type = this.projectType;
    let queryParams: ProjectQueryParams = {
      status: this.projectTypes[type as keyof typeof this.projectTypes],
      sessionUserId: user._id,
      offset: offset,
      limit: limit,
      markets: 'all',
    };
    if (this.searchText) {
      this.searchedItems = [this.searchText];
      queryParams.searchText = this.searchedItems[0];
      queryParams.searchBy = 'project';
    }

    this.projectService.getProjects(queryParams).subscribe({
      next: (response) => {
        this.projects = response.projects;
        this.projectsCount = response.count;
      },
    });
  }

  goToProjectDetails(project: any) {
    this.router.navigateByUrl(
      `/client/project/${this.projectType.toLowerCase()}/${project._id}/details`
    );
  }

  projectArchive(project: any) {
    this.projectService.projectArchive(project._id, { endMessage: '' });
  }

  projectRestore($event: Event, project: any) {
    $event.stopPropagation();
    this.ref = this.dialogService.open(UnarchiveProjectComponent, {
      header: 'Restart Project',
      width: '30vw',
      contentStyle: { overflow: 'auto' },
      breakpoints: {
        '960px': '75vw',
        '640px': '90vw',
      },
      data: [project.owner, project.codeName],
    });

    this.ref.onClose.subscribe((data) => {
      if (data) {
        this.projectService
          .projectRestore(project._id, { projectOwner: data.value })
          .subscribe((response) => {
            this.getProjects({ first: 0, rows: 10 });

            this.toastService.showSuccess('Project Restored.');
          });
      }
    });
  }

  onEdit($event: Event, project: Project): void {
    $event.stopPropagation();
    this.router.navigateByUrl(`/client/project/${project._id}/edit`);
  }
}
