<div class="align-items-center rating-container">
    <div class="col-12 mb-3 p-0">
        <div class="flex  flex-column align-items-start justify-content-center gap-2">
            <span class="font-medium text-sm">For Project <b>{{ratingInfo?.projectDetails?.codeName}}</b></span>
        </div>
    </div>
    <div class="col-12 mb-3 p-0">
        <div class="w-full flex flex-column gap-3 pb-3">
            <div class="align-items-center border-bottom-1 border-top-1 flex justify-content-between px-4 py-2 surface-border">
                <div class="flex align-items-center gap-4 text-sm">
                    <p>
                        Please take a moment to rate your experience for Freelancer: <b>{{ratingInfo?.firstName}} {{ratingInfo?.lastName}}</b>.
                    </p>
                </div>
                <div class="flex gap-1">
                    <p-button [rounded]="true" [text]="true" icon="true" severity="secondary"
                        (click)="$event.stopPropagation();op.toggle($event)" pTooltip="Add Notes"
                        tooltipPosition="top" pTooltip="Add notes">
                        <span class="material-symbols-outlined">
                            rate_review
                        </span>
                    </p-button>
                    <p-overlayPanel #op showCloseIcon="true" baseZIndex="99">
                        <div class="flex flex-column gap-3 w-25rem">
                            <textarea [(ngModel)]="notes" rows="5" maxlength="200" cols="30" pInputTextarea autofocus="true"
                                placeholder="Add a note of maximum 200 characters"></textarea>
                        </div>
                    </p-overlayPanel>
                    <p-button (click)="clickFavourite()" [rounded]="true" [text]="true" icon="true" severity="secondary"
                        [pTooltip]="!isFavourite ? 'Add to favorite list' : 'Remove from favorite list'" tooltipPosition="top">
                        <span class="material-symbols-outlined " [ngClass]="{'colored-star': isFavourite}">
                            bookmark_star
                        </span>
                    </p-button>
                    <p-button (click)="onClickRestrict()" [rounded]="true" [text]="true" icon="true" severity="secondary"
                        [pTooltip]="isRestricted ? 'Remove from Restricted list' : 'Move to Restricted list'" tooltipPosition="top">
                        <span class="material-symbols-outlined" [ngStyle]="{'color': isRestricted ? 'red' : '#311B92'}">
                            block
                        </span>
                    </p-button>
                    <p-button (click)="toggleWorkTogether()" [disabled]="rating < minRating" [rounded]="true" [text]="true" icon="true" severity="secondary"
                        [pTooltip]="!workTogether ? 'Add candidate to work together list' : 'Remove candidate from work together list'" tooltipPosition="top" [disabled]="rating < minRating">
                        <span class="material-symbols-outlined" [ngClass]="{'freshgreen': workTogether}">
                            group
                        </span>
                    </p-button>
                </div>
                <div class="flex align-items-center">
                    <p-rating (onRate)="applyWorkTogether()" [(ngModel)]="rating" [cancel]="false" pTooltip="Overall Rating" tooltipPosition="top" class="p-rating--sm" />
                </div>
            </div>
            
        </div>
    </div>
    <div class="flex justify-content-end gap-2">
        <p-button label="Cancel" [text]="true" [outlined]="true" [size]="'large'" (click)="onConfirm(false)" />
        <p-button label="Submit Rating" [size]="'large'" (click)="onConfirm(true)" />
    </div>
</div>
