<div class="flex flex-row justify-content-end" style="margin-bottom: -58px;">
    <p-button [link]="true" (click)="openGroupChat()" [rounded]="true" class="flex primary-background primary white-space-nowrap send-message-color">
        <span class="material-symbols-outlined "> forum </span>
        Send Group Message
    </p-button>
    <p-button [link]="true" (click)="showMailDialog(projectDetails._id, emailType.project, projectDetails.firm, 'All Staff', '', '')" [rounded]="true" class="ml-3 flex primary-background primary white-space-nowrap send-message-color">
        <span class="material-symbols-outlined">
            mail
        </span>
        Send Group Email
    </p-button>
</div>
<div class="table-options align-items-end justify-content-end">

</div>
<div class="grid-nogutter">
    <div class="col-12 p-0">
        <p-checkbox 
    [(ngModel)]="checked" 
    [binary]="true" 
    inputId="binary"
    class="my-3" />
    </div>
</div>
<p-table styleClass="p-datatable-sm p-datatable-sm--normal" [value]="projectStaffList" dataKey="_id" [tableStyle]="{ 'min-width': '60rem' }"
  [expandedRowKeys]="expandedRows" (onRowExpand)="onRowExpand($event)"
  (onRowCollapse)="onRowCollapse($event)">
  <ng-template pTemplate="header">
      <tr>
          <th pSortableColumn="name">
              Role
              <p-sortIcon field="name" />
          </th>
          <th pSortableColumn="code">
              Position
              <p-sortIcon field="code" />
          </th>
          <th style="width: 8%">Action</th>
      </tr>
  </ng-template>
  <ng-template pTemplate="body" class="border-0" let-projectStaffList let-expanded="expanded">
      <tr>
          <td >
            <div class="wrap-check-data flex align-items-center gap-3">
                <p-checkbox 
                [(ngModel)]="checked" 
                [binary]="true" 
                inputId="binary"
                />
                  {{ projectStaffList.roleType }}
            </div>
          </td>
          <td>{{ projectStaffList.positions}} Positions
              Listed/{{ projectStaffList.positionsFilled }} Filled</td>
          <td>
              <div class="wrap-actions flex align-items-center gap-2">
                  @if(projectStaffList.status !== 'ARCHIVE'){
                    <p-button pTooltip="Send Mail" 
                    tooltipPosition="top" [rounded]="true" (click)="showMailDialog(projectDetails._id, emailType.role, projectDetails.firm, projectStaffList.roleType, projectStaffList._id, '')" [text]="true" icon="true">
                        <span class="material-symbols-outlined">
                            mail
                        </span>
                    </p-button>
                    <p-button pTooltip="Create Thread On Role" 
                    tooltipPosition="top" (click)="openCreateChat(projectStaffList)"   [rounded]="true" [text]="true" icon="true">
                        <span class="material-symbols-outlined">
                            forum
                        </span>
                    </p-button>
                  }
                  <p-button [pTooltip]="expanded? 'Close' : 'Expand'" 
                  tooltipPosition="top" type="button" pRipple [pRowToggler]="projectStaffList" [text]="true"
                      [rounded]="true" [plain]="true" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-up'" 
                      class="row-toggler" />
              </div>
          </td>
      </tr>
  </ng-template>
 
    <ng-template pTemplate="rowexpansion" let-projectStaffList>
        @if(projectStaffList.positionsList.length > 0){
        <tr>
            <td colspan="7" class="p-0">
                <div class="inner-wrap surface-100 p-0">
                    <p-table [value]="projectStaffList.positionsList" dataKey="_id">
                        <ng-template pTemplate="header">
                            <tr>
                                <th pSortableColumn="Reviewer" class="pl-6">
                                    Candidate <p-sortIcon field="Reviewer" />
                                </th>
                                <th pSortableColumn="Date">
                                    Staff Date <p-sortIcon field="Date" />
                                </th>
                                <th pSortableColumn="Rate">
                                    Hourly Rate <p-sortIcon field="Rate" />
                                </th>
                                <th pSortableColumn="Status">
                                    Status <p-sortIcon field="Status" />
                                </th>
                                <th style="width: 11%" class="pr-40">Action</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-position>
                            <tr class="table-data">
                                <td class="pl-6 cursor-pointer" (click)="openReviewerDialog(position.reviewer._id)">{{ position.reviewer.firstName }} {{ position.reviewer.lastName }}</td>
                                <td>
                                    @if (position.status !== 'QUIT' && position.status !== 'FIRED' && position.status !== 'COMPLETED') {
                                        @if (projectStaffList.status === 'ARCHIVE') {
                                            <p>Ended {{position.finishedAt | date : 'MM/dd/yyyy'}}</p>
                                        }
                                        @if (projectStaffList.status !=='ARCHIVE' && (position.presumptive && position.presumptive.status !== 'APPROVED')) {
                                            <p>Accepted {{position.filledAt | date : 'MM/dd/yyyy'}}</p>
                                        }
                                        @if (projectStaffList.status !== 'ARCHIVE') {
                                            <p>Accepted {{position.filledAt | date : 'MM/dd/yyyy'}}</p>
                                        }
                                    }
                                    @if (position.status === 'QUIT' && position.status === 'FIRED' && position.status === 'COMPLETED' && position.status === 'OCCUPIED') {
                                        @if (position.status === 'QUIT') {
                                            <p>Quit {{position.finishedAt | date : 'MM/dd/yyyy'}}</p>
                                        }
                                        @if (position.status === 'FIRED') {
                                            <p>Fired {{position.finishedAt | date : 'MM/dd/yyyy'}}</p>
                                        }
                                        @if (position.status === 'COMPLETED') {
                                            <p>Ended {{position.finishedAt | date : 'MM/dd/yyyy'}}</p>
                                        }
                                    }
                                </td>
                                <td (click)="openReviewerDialog(position.reviewer._id)" class="cursor-pointer">
                                    Rate ${{projectStaffList.hourlyRate}}/{{projectStaffList.payType === 'salary' ? 'yr' : 'hr'}}
                                    @if (projectStaffList.overTime > 0) {
                                        <span>  (OT ${{projectStaffList.overTime}}/hr)</span>
                                    }
                                </td>
                                <td>
                                    <span class="tag-value {{ position.status | lowercase }}">
                                        {{ position.status | lowercase }}
                                    </span>
                                </td>
                                <td class="pr-40">
                                    @if (projectStaffList.status !== 'ARCHIVE') {
                                        <div class="wrap-actions wrap-buttons-expandable">
                                            @if(position.status !== 'FIRED' && position.status !== 'QUIT' && position.status !== 'COMPLETED'){
                                                <p-button pTooltip="Fire" 
                                                tooltipPosition="top" (click)="showFireReviewerDialog($event, position.reviewer._id, position._id, position.role, position.reviewer.firstName, position.reviewer.lastName)" [rounded]="true" [text]="true" icon="true">
                                                    <span class="material-symbols-outlined">
                                                        person_off
                                                    </span>
                                                </p-button>    
                                                <p-button  pTooltip="Chat" (click)="openCreateChatReviewer(position)" 
                                                tooltipPosition="top" [rounded]="true" [text]="true" icon="true">
                                                    <span class="material-symbols-outlined">
                                                        chat
                                                    </span>
                                                </p-button>
                                                <p-button pTooltip="Send Mail" 
                                                tooltipPosition="top" [rounded]="true" [text]="true" icon="true" (click)="showMailDialog(projectDetails._id, emailType.individual, projectDetails.firm, position.reviewer.firstName +' ' + position.reviewer.lastName, projectStaffList._id, position.reviewer._id)">
                                                    <span class="material-symbols-outlined">
                                                        mail
                                                    </span>
                                                </p-button>
                                            }  
                                        </div> 
                                    }
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </td>
        </tr>
        }
    </ng-template>
</p-table>
