import { Component } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogModule, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FloatLabelModule } from 'primeng/floatlabel';
import moment from 'moment';
import { PeopleService } from '../../../../../../../shared/services/people.service';
import { ToastService } from '../../../../../../../shared/services/toast.service';
import { UserService } from '../../../../../../../shared/services/user.service';
import { environment } from '../../../../../../../../environments/environment';
import { InputTextModule } from 'primeng/inputtext';

@Component({
  selector: 'app-shareable-invite',
  standalone: true,
  imports: [FloatLabelModule, ButtonModule, DynamicDialogModule, ButtonModule, ReactiveFormsModule, InputTextModule],
  templateUrl: './shareable-invite.component.html',
  styleUrl: './shareable-invite.component.scss'
})
export class ShareableInviteComponent {

  shareInviteForm!: FormGroup;
  inviteLink: string = '';

  constructor(private ref: DynamicDialogRef, 
    private fb: FormBuilder, 
    private peopleService: PeopleService,
    private userService: UserService,
    private toastService: ToastService) {}

  ngOnInit() {
    this.shareInviteForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  onSubmit() {
    if (this.shareInviteForm.valid) {
      const firmId = this.userService.getUserFirmId();
      let data = this.shareInviteForm.value;
      console.log(data);
      let body = {
        firmId: firmId,
        users: [data]
      };
      this.peopleService.inviteCandidates(body).subscribe((response: any) => {
          console.log(response);
          let user = response[0];
          let recoverHashUrl = user.recoverHash + ':' + moment().add(1, 'd').unix();
          this.inviteLink = environment.clientHost + 'rp?t=' + recoverHashUrl + '&externalInvite=' + user.externalInvite + '&firm=' + firmId;
          this.toastService.showSuccess('Link Generated Successfully');
          this.shareInviteForm.reset();
      });
    }
  }

  copyLink() {
    navigator.clipboard.writeText(this.inviteLink).then().catch(e => console.log(e));
  }
}
