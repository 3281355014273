<app-heading [title]="projectsTypeTitle" [button]="headingButton" [showToggle]="false"></app-heading>
<div class="grid grid-nogutter">
    <div class="col-12 p-0">
        <p-tabView styleClass="full-w" [(activeIndex)]="activeTabIndex">

            <p-tabPanel header="INFORMATION">
                @if(activeTabIndex === 0){
                    <app-project-info [id]="projectId" [project]="project" (updateProjectState)="updateProjectState()" (changeTab)="changeTab($event)"></app-project-info>
                }
            </p-tabPanel>

            <p-tabPanel header="DOCUMENT" [disabled]="!project?.status">
                @if(activeTabIndex === 1){
                    <app-project-documents [project]="project" (updateProjectState)="updateProjectState()" (changeTab)="changeTab($event)"></app-project-documents>
                }
            </p-tabPanel>

            <p-tabPanel header="ROLES" [disabled]="!project?.status">
                @if(activeTabIndex === 2){
                    <app-role [recievedRoleId]="roleId" (changeTab)="changeTab($event, true) " (sendOfferButtonFromRole)="changeTab($event, false)" (updateProjectState)="updateProjectState()"></app-role>
                }
            </p-tabPanel> 

            <p-tabPanel header="OFFERS" [disabled]="!project?.status || project?.status === status.created">
                @if(activeTabIndex === 3){
                    <app-project-offers [id]="projectId" [isDefaultSelectedRole]="isDefaultSelectedRole" [project]="project" (updateProjectState)="updateProjectState()" (changeTab)="changeTab($event)"></app-project-offers>
                }
            </p-tabPanel>

            <p-tabPanel header="REVIEW" [disabled]="!project?.status || project?.status === status.created">
                @if(activeTabIndex === 4){
                    <app-review [id]="projectId" [project]="project" (gotoRoleEditPage)="gotoRoleEditPage($event)" (changeTab)="gotoRoleEditPage($event)"></app-review>   
                }
            </p-tabPanel>
        </p-tabView>
    </div>
</div>

<p-overlayPanel #op>
    <div class="flex flex-column gap-3">
        <div *ngFor="let category of categories" class="field-checkbox">
            <p-checkbox 
                [(ngModel)]="selectedCategories"
                [label]="category.name"
                name="group"
                [value]="category" />
        </div>
    </div>
</p-overlayPanel>
