import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { TabViewModule } from 'primeng/tabview';
import { DialogModule } from 'primeng/dialog';
import { CommonModule } from '@angular/common';
import { InputTextModule } from 'primeng/inputtext';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputSwitchModule } from 'primeng/inputswitch';
import { FloatLabelModule } from "primeng/floatlabel";
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { InputNumberModule } from 'primeng/inputnumber';
import { CheckboxModule } from 'primeng/checkbox';
import { FormGroup, ReactiveFormsModule , FormsModule } from '@angular/forms';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ButtonModule } from 'primeng/button';
import { TableModule , TableRowCollapseEvent, TableRowExpandEvent } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { DragDropModule, CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ChipModule } from 'primeng/chip';
import { HeadingComponent } from '../../../../../shared/components/heading/heading.component';
import { RoleComponent } from './role/role.component';
import { InformationComponent } from './information/information.component';
import { OffersComponent } from './offers/offers.component';
import { ReviewComponent  } from './review/review.component';
import { ProjectDocumentsComponent } from './documents/documents.component';
import { ActivatedRoute } from '@angular/router';
import { ProjectService } from '../../../../../shared/services/project.service';
import { Project } from '../project.interface';
import { constants } from '../../../../../shared/constants/constants';
import { EditTemplateComponent } from './documents/edit-template/edit-template.component';

interface Column {
  field: string;
  header: string;
}
@Component({
    selector: 'app-create',
    standalone: true,
    templateUrl: './create.component.html',
    styleUrl: './create.component.scss',
    styles: [
        `
      :host ::ng-deep {
        [pDraggable] {
          cursor: move;
        }
      }
    `,
    ],
    encapsulation: ViewEncapsulation.None,
    imports: [
        ReviewComponent,
        DragDropModule,
        ReactiveFormsModule,
        OverlayPanelModule,
        CheckboxModule,
        DialogModule,
        CommonModule,
        TabViewModule,
        TableModule,
        InputNumberModule,
        FormsModule,
        IconFieldModule,
        InputIconModule,
        RadioButtonModule,
        ButtonModule,
        InputTextModule,
        InputSwitchModule,
        ChipModule,
        DropdownModule,
        FloatLabelModule,
        HeadingComponent,
        RoleComponent,
        InformationComponent,
        OffersComponent,
        ProjectDocumentsComponent,
        EditTemplateComponent
    ]
})
export class CreateComponent implements OnInit {
  headingButton = {
    url: '/client/project/create',
    label: 'CREATE PROJECT'
  }
  ingredient: any;
  City: any;
  cities: any;
  selectedCity: any;
  checked: boolean = false;
  visible: boolean = false;
  value1: number = 0;
  selectedProducts!: any;
  cols!: Column[];
  formGroup: FormGroup | undefined;
  selectedCategories: any[] = [];
  availableProducts: any[] | undefined;
  draggedProduct: any | undefined | null;
  projectId: string | null = null;
  project: Project | null = null;
  activeTabIndex = 0;
  status = constants.projectStatus;
  projectsTypeTitle: string = 'Create Project';
  roleId: any = null;
  categories: any[] = [
    { name: 'Worked Together', key: 'X' },
    { name: 'In-House', key: 'Y' },
    { name: 'Assigned', key: 'Z' },
    { name: 'Restricted', key: 'V' },
    { name: 'Conflicted', key: 'A' },
    { name: 'Rejected', key: 'M' },
    { name: 'Previously Assigned ', key: 'P' },
    { name: 'Talent Pool', key: 'R' }
  ];
  isDefaultSelectedRole = true;

  showDialog() {
    this.visible = true;
  }

  constructor(
    private route: ActivatedRoute,
    private projectService: ProjectService,
  ){ }
  
  ngOnInit() {
    this.cols = [
      { field: 'name', header: 'Name' },
      { field: 'worked', header: 'Project Worked' },
      { field: 'together', header: 'Projects Together' },
      { field: 'conflict', header: 'Conflict' },
      { field: 'actions', header: 'Action' },
    ];

    this.selectedProducts = [];
    this.availableProducts = [
      { id: '1', name: 'staff 1' },
      { id: '2', name: 'staff 2' },
      { id: '3', name: 'staff 3' },
      { id: '4', name: 'staff 4' },
      { id: '5', name: 'staff 5' },
      { id: '6', name: 'staff 6' },
      { id: '7', name: 'staff 7' },
      { id: '8', name: 'staff 8' },
      { id: '9', name: 'staff 9' },
      { id: '10', name: 'staff 10' },
    ];
    this.initializeEdit();
  }
  onRowExpand(event: TableRowExpandEvent) {}
  onRowCollapse(event: TableRowCollapseEvent) {}

  dragStart(product: any) {
    this.draggedProduct = product;
  }

  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.availableProducts!, event.previousIndex, event.currentIndex);
  }

  dragEnd() {
    this.draggedProduct = null;
  }

  findIndex(product: any) {
    let index = -1;
    for (let i = 0; i < (this.availableProducts as any[]).length; i++) {
        if (product.id === (this.availableProducts as any[])[i].id) {
            index = i;
            break;
        }
    }
    return index;
  }

  
  initializeEdit(): void{
    this.route.paramMap.subscribe(params => {
      this.projectId = params.get('projectId') || null;
      if(this.projectId){
        this.getProjectById();
        this.projectsTypeTitle = 'Edit Project'
      }
    });
  }

  updateProjectState(): void{
    this.getProjectById();
  }

  getProjectById(): void{
    this.projectService.getProjectById(this.projectId || '').subscribe({
      next: (project: Project) => {
        this.project = project;
        this.activeTab();
      }
    });
  }

  activeTab(): void{
    switch (this.project?.step) {
      case '2':
        this.activeTabIndex = 1;
        break;
      case '5':
        this.activeTabIndex = 2;
        break;
      case '7':
        this.activeTabIndex = 4;
        break;
      default:
        break;
    }
  }

  changeTab(index: number = 0, isDefault: boolean = true): void {
    this.initializeProjectStep();
    this.activeTabIndex = index;
    this.isDefaultSelectedRole = isDefault;
  }

  gotoRoleEditPage(event: any): void{
    this.changeTab(event.index);
    this.roleId = event.roleId;
  }

  initializeProjectStep(): void{
    this.roleId = null;
  }
}
