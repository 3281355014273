import { Injectable } from '@angular/core';
import { HttpBaseService } from './http-base.service';
import { HttpParams } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { Role } from '../../modules/client/dashboard/project/project.interface';

@Injectable({
  providedIn: 'root'
})
export class RoleService {
  role!: Role;
  constructor(private http: HttpBaseService) { }

  createProjectRole(projectId: string, role: any) {
    return this.http.post('projects/' + projectId + '/roles', role);
  };

  updateProjectRole(projectId: string, roleId: string, role: any) {
    return this.http.put('projects/' + projectId + '/roles/' + roleId, role);
  };

  deleteProjectRole(projectId: string, roleId: string) {
    return this.http.delete('projects/' + projectId + '/roles/' + roleId);
  };

  getProjectRoles(projectId: string) {
    return this.http.get('projects/' + projectId + '/roles');
  };

  getProjectRole(projectId: string, roleId: string) {
    return this.http.get('projects/' + projectId + '/roles/' + roleId);
  };

  getReviewersCount(projectId: string, role: any, reviewerType: any) {
    let options: any = {'ignoreLoader': true};
    if (reviewerType) {
      options.params = {reviewerType: reviewerType};
    }
    return this.http.put('projects/' + projectId + '/roles/reviewersCount', role, options);
  };

  getReviewersCounts(projectId: string, roles: any) {
    return this.http.put('projects/' + projectId + '/roles/reviewersCounts', roles);
  };


  async getProjectRoleReviewers(projectId: string, roleId: string, fetchAllReviewers: boolean){
    
    const params = fetchAllReviewers ? '?getAllReviewersOnRole=true' : '';
    const response = await lastValueFrom(this.http.get(`projects/${projectId}/roles/${roleId}/roleReviewers${params}`, { params }));
    if(response && response.reviewers && response.reviewers.length){
      response.reviewers = response.reviewers.sort((current: any, next: any) => {
        if(current.lastName < next.lastName){
          return -1;
        }
        if(current.lastName > next.lastName){
          return 1;
        }
        return 0;
      });
      return response;
    }
    return null;
  }
}
