import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputTextModule } from 'primeng/inputtext';
import { ToastService } from '../../../../../shared/services/toast.service';
import { Router } from '@angular/router';
import { PeopleService } from '../../../../../shared/services/people.service';
import { UserService } from '../../../../../shared/services/user.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ShareableInviteComponent } from '../modals/shareable-invite/shareable-invite/shareable-invite.component';
import { BulkInviteComponent } from '../modals/bulk-invite/bulk-invite/bulk-invite.component';
import { constants } from '../../../../../shared/constants/constants';

@Component({
  selector: 'app-invite-candidate',
  standalone: true,
  imports: [
    FloatLabelModule,
    FormsModule,
    InputTextModule,
    CommonModule,
    ReactiveFormsModule,
    ButtonModule,
  ],
  templateUrl: './invite-candidate.component.html',
  styleUrl: './invite-candidate.component.scss'
})
export class InviteCandidateComponent {

  inviteForm!: FormGroup;
  visible: boolean = false;
  ref: DynamicDialogRef | undefined;
  bulkInviteRef: DynamicDialogRef | undefined;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    public dialogService: DialogService,
    private peopleService: PeopleService,
    private userService: UserService,
    private toastService: ToastService
  ) {}

  ngOnInit() {
    this.inviteForm = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.required,Validators.pattern(constants.emailRegex)]],
      phone: [''],
    });
  }

  onSubmit() {
    if (this.inviteForm.valid) {
      let data = {
        ...this.inviteForm.value,
        phone: this.inviteForm.controls['phone'].value.replace(/-/g, '')
      };
      let body = {
        firmId: this.userService.getUserFirmId(),
        users: [data]
      };
      this.peopleService.inviteCandidates(body).subscribe((response: any) => {
          this.toastService.showSuccess('Invite Sent Successfully');
          this.inviteForm.reset();
      });
    }
  }

  back() {
    this.router.navigate([`/client/people/all`]);
  }

  showShareableInviteDialog($event: Event) {
    $event.stopPropagation();
    this.ref = this.dialogService.open(ShareableInviteComponent, {
      header: 'Shareable Invite Link',
      width: '30vw',
      contentStyle: { overflow: 'auto' },
      breakpoints: {
        '960px': '75vw',
        '640px': '90vw'
      }
    });
  }

  showBulkInviteDialog() {
    this.bulkInviteRef = this.dialogService.open(BulkInviteComponent, {
      header: 'Bulk Invite Candidates',
      width: '80vw',
      contentStyle: { overflow: 'auto' },
      breakpoints: {
        '960px': '75vw',
        '640px': '90vw'
      }
    });
  }

  hideDialog(){
    this.visible = false;
  }

  getFormValue(value: string) {
    return this.inviteForm.get(value);
  }

  formatContact(event: any): void {
    let input = event.target.value.replace(/\D/g, '');
    let formattedInput = '';
    if (input.length > 0) {
      formattedInput = input.substring(0, 3);
  }
  if (input.length > 3) {
      formattedInput += '-' + input.substring(3, 6);
  }
  if (input.length > 6) {
      formattedInput += '-' + input.substring(6, 10);
  }
    event.target.value = formattedInput;  
  }
}
