<div header="Archive Project" class="align-items-center">
  
  <form [formGroup]="shareInviteForm">
    <div class="grid grid-nogutter">
  
      <div class="col-12 pb-3 pt-0 pl-0 pr-0">
        <p-floatLabel>
          <input formControlName="email" type="email"  pInputText />
          <label for="email">Email</label>
        </p-floatLabel>
      </div>
    </div>
    <div class="flex justify-content-end">
      <p-button
          class=""
          (click)="onSubmit()"
          [rounded]="true"
          [disabled]="!shareInviteForm.valid"
        >
        Generate link
        </p-button>
    </div>
  </form>

  @if (inviteLink) {
    <div class="mp-form-field-wrap mt-3" layout="row">
      <div class="mp-form-field pb-3 ">
        <p-floatLabel>
          <input value="{{inviteLink}}" id="shareableLink" name="shareableLink" type="text" pInputText disabled readonly />
        </p-floatLabel>
      </div>
      <div>
        <div class="flex justify-content-end">
          <p-button
          class=""
          (click)="copyLink()"
          [rounded]="true"
        >
        Copy
        </p-button>
        </div>
      </div>
    </div>
  }
</div>
