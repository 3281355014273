@if (!showCreateForm) {
    <div class="grid grid-nogutter">
        @if (this.project) {
            <div class="p-0 mb-2 col-12 flex justify-content-start lg:justify-content-between flex-column lg:flex-row align-items-start lg:align-items-center gap-3 lg:gap-0 ">
                <p class="m-0">What type of candidates/cyber professionals are needed for <span class="font-medium">{{this.project!.codeName}}</span></p>
                <p-button label="CREATE ROLE" (click)="onCreate()" [rounded]="true" icon="pi pi-plus" size="large"/>
            </div>
        }
        <div class="col-12 p-0 mb-2">
            <p class="m-0 text-black-alpha-60 lg:mt-0 xl:mt-0 mt-4 ">You’ve entered: {{projectRoles.length}} {{projectRoles.length == 1 ? 'role' : 'roles'}}</p>
        </div>
        <div class="w-full">
            <div class="col-12 p-0">
                <p-table [value]="projectRoles" styleClass="p-datatable-sm p-datatable-sm--normal" dataKey="_id" [tableStyle]="{ 'min-width': '60rem' }"
                    [expandedRowKeys]="expandedRows" (onRowExpand)="onRowExpand($event)"
                    (onRowCollapse)="onRowCollapse($event)">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>
                                Role Name
                            </th>
                            <th>
                                Start Date/Time
                            </th>
                            <th>
                                Freelancer Available 
                            </th>
                            <th style="width: 8%">Action</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" class="border-0" let-role let-expanded="expanded">
                        <tr>
                            <td>{{ role.roleType }}</td>
                            <td>{{ role.startAt | date }}</td>
                            <td>{{ role.reviewersCount }}</td>
                            <td>
                                <div class="wrap-actions flex align-items-center gap-2">
                                    @if(role.status === roleStatus.active){
                                        <p-button (click)="onEdit(role._id)" [rounded]="true" [text]="true" icon="true" plain="true">
                                            <span class="material-symbols-outlined">
                                                edit
                                            </span>
                                        </p-button>
                                        <p-button (click)="openDeleteModal(role)" [rounded]="true" [text]="true" icon="true" plain="true">
                                            <span class="red material-symbols-outlined">
                                                delete
                                            </span>
                                        </p-button>
                                        <p-button pTooltip="Send Offer" tooltipPosition="top" (click)="sendOffer(role)" [rounded]="true"
                                            [text]="true" icon="true" plain="true">
                                            <span class="material-symbols-outlined">
                                                send
                                            </span>
                                        </p-button>
                                    }
                                    @if(role.status === roleStatus.archive){
                                    <p-button (click)="openDeleteModal(role)" [rounded]="true" [text]="true" icon="true" plain="true">
                                        <span class="material-symbols-outlined">
                                            unarchive
                                        </span>
                                    </p-button>
                                    }
                                   
                                    
                                    <p-button type="button" pRipple [pRowToggler]="role" [text]="true"
                                        [rounded]="true" [plain]="true" [icon]="
                        expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-up'
                        " class="row-toggler" />
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="rowexpansion" let-role>
                        <tr>
                            <td colspan="4" class="p-0">
                                <div class="sub-details surface-100 py-3 px-4">
                                    <div class="grid grid-nogutter">
                                        <div class="py-0 pr-4 pl-0 col-12 md:col-12 sm:col-12">
                                            <span class="text-base block font-medium mt-3">Project Type</span>
                                          </div>
                                          <div class="py-0 pr-4 pl-0 col-2 md:col-2 sm:col-2">
                                            @for(job of roleDetailsDisplay?.jobType; track job){
                                              <p class="m-0 text-black-alpha-60 font-medium text-sm">
                                                {{ job }}
                                              </p>
                                            }
                                          </div>
                                        <div class="py-0 pr-4 pl-0 col-12 md:col-12 sm:col-12">
                                            <span class="text-base block font-medium mt-3">Education</span>
                                          </div>
                                          <div class="py-0 pr-4 pl-0 col-2 md:col-2 sm:col-2">
                                            <span class="text-primary mt-2 block text-xs">Under Graduate</span>
                                           @if(roleDetailsDisplay?.underGrad?.length){
                                              @for(education of roleDetailsDisplay?.underGrad; track education){
                                                  <p class="m-0 text-black-alpha-60 font-medium text-sm">{{education.type}}-{{education.major}}</p>
                                              }
                                            } 
                                            @else{
                                              <p class="m-0 text-black-alpha-60 font-medium text-sm">Any</p>
                                            }      
                                          </div>
                                          <div class="py-0 pr-4 pl-0 col-2 md:col-2 sm:col-2">
                                            <span class="text-primary mt-2 block text-xs">Graduate</span>
                                            @if(roleDetailsDisplay?.graduate?.length){
                                                @for(education of roleDetailsDisplay?.graduate; track education){
                                                    <p class="m-0 text-black-alpha-60 font-medium text-sm">{{education.type}}-{{education.major}}</p>
                                                }
                                              }
                                              @else{
                                                <p class="m-0 text-black-alpha-60 font-medium text-sm">Any</p>
                                              }
                                            </div>
                                            <div class="py-0 pr-4 pl-0 col-12 md:col-12 sm:col-12">
                                              <span class="text-base block font-medium mt-3">Skills and Experience</span>
                                            </div>
                                            @if(roleDetailsDisplay?.skillsAndExperience?.length){
                                              <div class="py-0 pr-4 pl-0 col-2 md:col-2 sm:col-2">
                                                @for(skill of roleDetailsDisplay?.skillsAndExperience; track skill){
                                                  <p class="m-0 text-black-alpha-60 font-medium text-sm">{{skill}}</p>
                                                }
                                              </div>
                                            }
                                            @else{
                                              <p class="m-0 text-black-alpha-60 font-medium text-sm">Any</p>
                                            }
                                            <div class="py-0 pr-4 pl-0 col-12 md:col-12 sm:col-12">
                                                <span class="text-base block font-medium mt-3">Credentials</span>
                                              </div>
                                              @if(roleDetailsDisplay?.credential?.length){
                                                <div class="py-0 pr-4 pl-0 col-2 md:col-2 sm:col-2">
                                                  @for(cred of roleDetailsDisplay?.credential; track cred){
                                                    <p class="m-0 text-black-alpha-60 font-medium text-sm">{{cred.val}}</p>
                                                  }
                                                </div>
                                              }
                                              @else{
                                                <p class="m-0 text-black-alpha-60 font-medium text-sm">Any</p>
                                              }
                                        <div class="py-0 pr-4 pl-0 col-12 md:col-12 sm:col-12">
                                            <span class="text-base block font-medium mt-3">Timeline and Schedule</span>
                                        </div>
                                        <div class="py-0 pr-4 pl-0 col-2 md:col-2 sm:col-2">
                                            <span class="text-primary mt-2 block text-xs">Starts On</span>
                                            <p class="m-0 text-black-alpha-60 font-medium text-sm">{{role.startAt | date}}</p>
                                        </div>
                                        <div class="py-0 pr-4 pl-0 col-2 md:col-2 sm:col-2">
                                            <span class="text-primary mt-2 block text-xs">Monday - Friday</span>
                                            @if (role.workSchedule.schedule.weekDays.willWork) {
                                                <p class="m-0 text-black-alpha-60 font-medium text-sm">Start {{helperService.leftPaddingTime(role.workSchedule.schedule.weekDays.time.start.hour)}}:{{helperService.leftPaddingTime(role.workSchedule.schedule.weekDays.time.start.min)}} {{helperService.leftPaddingTime(role.workSchedule.schedule.weekDays.time.start.meridiem)}}</p>
                                                <p class="m-0 text-black-alpha-60 font-medium text-sm">End {{helperService.leftPaddingTime(role.workSchedule.schedule.weekDays.time.end.hour)}}:{{helperService.leftPaddingTime(role.workSchedule.schedule.weekDays.time.end.min)}} {{helperService.leftPaddingTime(role.workSchedule.schedule.weekDays.time.end.meridiem)}}</p>
                                            }
                                            @if (!role.workSchedule.schedule.weekDays.willWork) {
                                                <p class="m-0 text-black-alpha-60 font-medium text-sm">Closed</p>
                                            }
                                        </div>
                                        <div class="py-0 pr-4 pl-0 col-2 md:col-2 sm:col-2">
                                            <span class="text-primary mt-2 block text-xs">Expected Duration</span>
                                            <p class="m-0 text-black-alpha-60 font-medium text-sm">{{role.workSchedule.duration}}</p>
                                        </div>
                                        <div class="py-0 pr-4 pl-0 col-2 md:col-2 sm:col-2">
                                            <span class="text-primary mt-2 block text-xs">Saturday</span>
                                            @if (role.workSchedule.schedule.saturday.willWork) {
                                                <p class="m-0 text-black-alpha-60 font-medium text-sm">Start {{role.workSchedule.schedule.saturday.time.start.hour}}:{{role.workSchedule.schedule.saturday.time.start.min}} {{role.workSchedule.schedule.saturday.time.start.meridiem}}</p>
                                                <p class="m-0 text-black-alpha-60 font-medium text-sm">End {{role.workSchedule.schedule.saturday.time.end.hour}}:{{role.workSchedule.schedule.saturday.time.end.min}} {{role.workSchedule.schedule.saturday.time.end.meridiem}}</p>
                                            }
                                            @if (!role.workSchedule.schedule.saturday.willWork) {
                                                <p class="m-0 text-black-alpha-60 font-medium text-sm">Closed</p>
                                            }
                                        </div>
                                        <div class="py-0 pr-4 pl-0 col-2 md:col-2 sm:col-2">
                                            <span class="text-primary mt-2 block text-xs">Expected hours</span>
                                            <p class="m-0 text-black-alpha-60 font-medium text-sm">{{role.workSchedule.workHours}}</p>
                                        </div>
                                        <div class="py-0 pr-4 pl-0 col-2 md:col-2 sm:col-2">
                                            <span class="text-primary mt-2 block text-xs">Sunday</span>
                                            @if (role.workSchedule.schedule.sunday.willWork) {
                                                <p class="m-0 text-black-alpha-60 font-medium text-sm">Start {{role.workSchedule.schedule.sunday.time.start.hour}}:{{role.workSchedule.schedule.sunday.time.start.min}} {{role.workSchedule.schedule.sunday.time.start.meridiem}}</p>
                                                <p class="m-0 text-black-alpha-60 font-medium text-sm">End {{role.workSchedule.schedule.sunday.time.end.hour}}:{{role.workSchedule.schedule.sunday.time.end.min}} {{role.workSchedule.schedule.sunday.time.end.meridiem}}</p>
                                            }
                                            @if (!role.workSchedule.schedule.sunday.willWork) {
                                                <p class="m-0 text-black-alpha-60 font-medium text-sm">Closed</p>
                                            }
                                        </div>
                                        <div class="py-0 pr-4 pl-0 col-12 md:col-12 sm:col-12">
                                            <span class="text-base block font-medium mt-3">Screening Questions</span>
                                        </div>
                                        <div class="py-0 pr-4 pl-0 col-2 md:col-2 sm:col-2">
                                            <span class="text-primary mt-2 block text-xs">Case Captions</span>
                                            <p class="m-0 text-black-alpha-60 font-medium text-sm">{{project?.caption}}</p>
                                        </div>
                                        @if(role?.hasConflict ){
                                            <div class="py-0 pr-4 pl-0 col-2 md:col-2 sm:col-2">
                                                <span class="text-primary mt-2 block text-xs">Conflicts Checked Against</span>
                                                @if(role?.scanForConflicts?.fullCompanyNames){
                                                <p class="m-0 text-black-alpha-60 font-medium text-sm">
                                                  Full Company Names
                                                </p>
                                                } @if(role?.scanForConflicts?.specificQuestions){
                                                <p class="m-0 text-black-alpha-60 font-medium text-sm">
                                                  Questions
                                                </p>
                                                }
                                              </div>
                                        
                                          @if(role?.scanForConflicts?.specificQuestions){
                                        @if(role?.conflictQuestions?.length){
                                            <div class="py-0 pr-4 pl-0 col-2 md:col-2 sm:col-2">
                                              <span class="text-primary mt-2 block text-xs">Questions</span>
                                              <ul class="m-0 list-none p-0 text-black-alpha-70 text-xs">
                                                @for(question of role.conflictQuestions; track question){
                                                <li>Q{{ $index + 1 }} - {{ question?.title }}</li>
                                                }
                                              </ul>
                                            </div>
                                            <div class="py-0 pr-4 pl-0 col-2 md:col-2 sm:col-2">
                                              <span class="text-primary mt-2 block text-xs">Answers</span>
                                              <ul class="m-0 list-none p-0 text-black-alpha-70 text-xs">
                                                @for(question of role.conflictQuestions; track question){
                                                <li>A{{ $index + 1 }} - {{ question?.answer? 'Yes': 'No' }}</li>
                                                }
                                              </ul>
                                            </div>
                                            }
                                        }
                                    } @else {
                                        <div class="py-0 pr-4 pl-0 col-2 md:col-2 sm:col-2">
                                            <span class="text-primary mt-2 block text-xs">Conflict Questions</span>
                                            <p class="m-0 text-black-alpha-60 font-medium text-sm">No conflict questions</p>
                                        </div>
                                      }
                                        
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
              </div>
               <!-- delete role modal -->
    <p-dialog 
    [modal]="true" 
    draggable="false"
    [(visible)]="visibleDeleteModal" 
    [breakpoints]="{ '1199px': '75vw', '767px': '90vw' }" 
    [style]="{ width: '660px', height: selectedDeleteRole?.status === roleStatus.archive ? '50vh' : 'auto' }" >

    <ng-template pTemplate="header">
        <div class="inline-flex align-items-center justify-content-center gap-2">
            <span class="font-bold white-space-nowrap">
               Confirmation
            </span>
        </div>
    </ng-template>

    <div class="grid grid-nogutter">
        <div class="col-12 p-0 flex justify-content-between align-items-center">
            <p>  You are about to
                @if(selectedDeleteRole?.status === roleStatus.active){
                    End
                }@else {
                    Restart
                }
                <span class="font-bold">{{selectedDeleteRole?.roleType}}</span>
                Role:
            </p>
          </div>
        @if(selectedDeleteRole?.status === roleStatus.active){
            <div class="col-12 p-0 flex justify-content-between align-items-center mt-2">
                <textarea placeholder="Comment or reason for role end to be sent to reviewer(s)." rows="3" [(ngModel)]="deleteRoleComment"  pInputText id="end-reason" class="flex-auto" autocomplete="off" ></textarea>
            </div>
        }
        @if(isUnreadMessagesExist){
            <div class="col-12 p-0 flex justify-content-between align-items-center mt-2" ng-if="vm.discussions.length">
                <p class="font-twntwo">
                    <b>Note: </b> There are unread messages from one or more reviewer whose role is about to end.
                    These messages will not be readable if you continue. To read messages click “Cancel” and
                    navigate to the messaging screen.
                </p>
            </div>
        }
        @if(selectedDeleteRole?.status === roleStatus.archive){
            <p>
                Please select a new start date and time for the role.
            </p>
        <div class="col-12 p-0 flex justify-content-between align-items-center mt-2">
            <input type="text" style="display: none;" />
            <p-floatLabel>
                <p-calendar 
                    [(ngModel)]="restartDate"
                    inputId="restart_date" 
                    [minDate]="roleMinDate"
                    [maxDate]="roleMaxDate"
                    placeholder="mm/dd/yy"/>
                <label for="restart_date">Start Date</label>
            </p-floatLabel>
        </div>
       
        <div class="col-12 p-0 flex justify-content-between align-items-center mt-2">
           
                                            <p-calendar 
                                            inputId="calendar-timeonly" 
                                            [(ngModel)]="restartTime"
                                            [timeOnly]="true" 
                                            hourFormat="12"
                                            inline="true"
                                            styleClass="slider-style"
                                            />
        </div>
    }
    </div>

    <ng-template pTemplate="footer">
        <p-button label="Cancel" size="large" [rounded]="true" (onClick)="hideDeleteModal()"/>
        <p-button label="Ok" size="large" [rounded]="true" (onClick)="deleteRole()"/>
    </ng-template>
</p-dialog>
        </div>
        <div class="col-12 p-0 flex justify-content-end gap-3 mt-3">
            <p-button label="Previous" (click)="goToPrevious(1)" [outlined]="true" [rounded]="true" size="large"/>
            @if(project?.step !== '7'){
                <p-button label="Save/Finish Later" (click)="saveAndLogout()" [rounded]="true"  size="large"/>
            }
            @if(isCreated){
                <p-button 
                    label="Publish and Next"
                    (click)="publish('next')"
                    [rounded]="true"
                    size="large"
                    [disabled]="!isStartDateWithin7Days || disablePublishButton || !!validateRolesStartDate(role).length"
                    />
            } @else {
                <p-button label="Next" (click)="next('next')" [rounded]="true"  size="large"/>
            }

            @if(project?.step === '7'){
                <p-button label="Save And Go To Review" (click)="next('review')" [rounded]="true"  size="large"/>
            }
        </div>
    </div>
    <p-dialog 
            [modal]="true" 
            draggable="false"
            [(visible)]="visiblePublishModal" 
            [breakpoints]="{ '1199px': '75vw', '767px': '90vw' }" 
            [style]="{ width: '660px' }" >

            <ng-template pTemplate="header">
                <div class="inline-flex align-items-center justify-content-center gap-2">
                    <span class="font-bold white-space-nowrap">
                        You are about to publish project.
                    </span>
                </div>
            </ng-template>

            <div class="grid grid-nogutter">
                <div class="col-12 p-0 flex justify-content-between align-items-center mt-3">
                    {{project?.codeName}}
                </div>
                <div class="col-12 p-0 flex justify-content-between align-items-center mt-3">
                    Please click "Ok" to continue. If not, please click "Cancel"
                </div>
            </div>

            <ng-template pTemplate="footer">
                <p-button label="Cancel" size="large" [rounded]="true" (onClick)="confirmPublish()"/>
                <p-button label="OK" size="large" [rounded]="true" (onClick)="confirmPublish(true)"/>
            </ng-template>
        </p-dialog>

} @else {
    <div class="w-full">
        <div class="col-11 p-0 mb-8 mx-auto">
            <div class="grid grid-nogutter">
                <div class="col-12 p-0">
                    <span class="text-base font-bold">{{roleId ? 'Edit Role' : 'Add New Role'}}</span>
                </div>
                <div class="col-12 flex justify-content-center my-3 p-2 bg-deep-purple-900 gap-5 align-items-center">
                    <span class="text-sm font-medium text-white">Revievers available based on the following criteria: 0 </span>
                    @if (showInhouseReviewer) {
                        <span class="text-sm font-medium text-white">In-house Revievers available based on the following criteria: {{occupiedReviewersCount}} </span>
                    }
                </div>
                <div class="col-12">
                        <p-tabView [(activeIndex)]="activeIndex" styleClass="full-w">
                            <p-tabPanel header="INFORMATION">
                                <form [formGroup]="informationForm" (ngSubmit)="onSubmitInformationForm()">
                                <div class="grid grid-nogutter">
                                    <div class="p-0 mb-1 xl:mb-4 lg:mb-4 col-12 xl:col-6 lg:col-6">
                                        <p-floatLabel>
                                            <input formControlName="roleType" id="username" type="text" pInputText />
                                            <label for="username">Role Name</label>
                                        </p-floatLabel>
                                        @if(infoForm['roleType'].dirty || infoForm['roleType'].touched || submitted){
                                            @if(infoForm['roleType'].errors?.['required']){
                                                <div class="p-error ">This is required.</div>
                                            }
                                        }
                                    </div>
                                    <div class="p-0 mb-0 sm:mb-4 md:mb-4 col-12 xl:col-6 lg:col-6 pl-0 xl:pl-4 lg:pl-4">
                                        <div class="switch-w-label pt-2 flex gap-2">
                                            <p-inputSwitch class="mpl-switch" inputId="stfProject" 
                                            [(ngModel)]="showInhouseReviewer" [ngModelOptions]="{standalone: true}" 
                                            (ngModelChange)="onShowInhouseReviewerSwitch()" />
                                            <label for="stfProject" class="line-height-2">Show in-house candidate count</label>
                                        </div>
                                    </div>
                                    <div class="p-0 mb-2 col-12 xl:col-6 lg:col-6">        
                                        <p-floatLabel>
                                            <p-multiSelect 
                                                formControlName="jobType" 
                                                inputId="float-label" 
                                                [options]="jobTypes"
                                                optionLabel="name" />
                                            <label for="float-label">Job Type</label>
                                        </p-floatLabel>
                                        @if(infoForm['jobType'].dirty || infoForm['jobType'].touched || submitted){
                                            @if(infoForm['jobType'].errors?.['required']){
                                                <div class="p-error ">This is required.</div>
                                            }
                                        }
                                    </div>
                                    <div class="p-0 mb-0 sm:mb-4 md:mb-4 col-12 xl:col-6 lg:col-6 pl-0 xl:pl-4 lg:pl-4"></div>
                                    <div class="p-0 mb-2 col-12 xl:col-6 lg:col-6">        
                                        <p-floatLabel>
                                            <p-multiSelect 
                                                formControlName="searchMarket" 
                                                inputId="float-label" 
                                                [options]="searchMarkets"
                                                optionLabel="name" />
                                            <label for="float-label">Search Markets</label>
                                        </p-floatLabel>
                                        @if(infoForm['searchMarket'].dirty || infoForm['searchMarket'].touched || submitted){
                                            @if(infoForm['searchMarket'].errors?.['required']){
                                                <div class="p-error ">This is required.</div>
                                            }
                                        }
                                    </div>
                                    <div class="p-0 mb-3 col-12">
                                        <span class="text-base font-semibold">Education</span>
                                    </div>
                                    <div class="p-0 mb-4 col-12 xl:col-6 lg:col-6">
                                        
                                    </div>
                                    <div class="p-0 col-12 xl:col-6 lg:col-6"></div>
                                    <div class="p-0 mb-1 xl:mb-4 lg:mb-4 col-12 xl:col-6 lg:col-6">

                                        <div class="flex align-items-center mb-2">
                                            <p-checkbox 
                                                [binary]="true" 
                                                formControlName="underGraduateAny"
                                                inputId="underGraduateAny"
                                                class="mr-2"/>
                                            <label for="underGraduateAny">Any</label>
                                        </div>
                                        <p-floatLabel>
                                            <p-multiSelect 
                                                formControlName="underGraduate" 
                                                inputId="float-label" 
                                                [options]="degrees.underGraduate"
                                                optionLabel="typeMajor" />
                                            <label for="float-label">Undergraduate degrees</label>
                                        </p-floatLabel>
                                        <span class="text-xs text-black-alpha-60 pl-3">Select upto five</span>
                                        @if(infoForm['underGraduate'].dirty || infoForm['underGraduate'].touched || submitted){
                                            @if(infoForm['underGraduate'].errors?.['required']){
                                                <div class="p-error ">This is required.</div>
                                            }
                                        }
                                    </div>
                                    <div class="p-0 col-12 xl:col-6 lg:col-6"></div>
                                    <div class="p-0 mb-4 col-12 xl:col-6 lg:col-6">
                                        <div class="flex align-items-center mb-2">
                                            <p-checkbox 
                                                [binary]="true" 
                                                formControlName="graduateAny"
                                                inputId="graduateAny"
                                                class="mr-2"/>
                                            <label for="graduateAny">Any</label>
                                        </div>
                                        <p-floatLabel>
                                            <p-multiSelect 
                                                formControlName="graduate" 
                                                inputId="float-label" 
                                                [options]="degrees.graduate"
                                                optionLabel="typeMajor" />
                                            <label for="float-label">Graduate degrees</label>
                                        </p-floatLabel>
                                        <span class="text-xs text-black-alpha-60 pl-3">Select upto five</span>
                                        @if(infoForm['graduate'].dirty || infoForm['graduate'].touched || submitted){
                                            @if(infoForm['graduate'].errors?.['required']){
                                                <div class="p-error ">This is required.</div>
                                            }
                                        }
                                    </div>
                                    <div class="p-0 mb-2 col-12 xl:col-6 lg:col-6"></div>
                                    <div class="p-0 mb-2 col-12">
                                        <span class="text-base font-semibold">Credentials</span>
                                    </div>
                                    <div class="p-0 mb-4 col-12 xl:col-6 lg:col-6">
                                        <p-floatLabel>
                                            <p-multiSelect
                                            inputId="float-label" 
                                            formControlName="dynamicCredential" 
                                            [options]="dynamicCredentialList"
                                            (onChange)="onChangeDynamicCredentialList()"
                                            optionLabel="name" />
                                        <!--     <p-dropdown (onChange)="onChangeDynamicCredentialList()" 
                                                formControlName="dynamicCredential" 
                                                inputId="float-label" 
                                                [options]="dynamicCredentialList"
                                                optionLabel="name" /> -->
                                            <label for="float-label">Credential Template</label>
                                        </p-floatLabel>
                                    </div>
                                    @if (selectedDynamicCredential) {
                                        <div class="p-0 mb-4 col-12 xl:col-6 lg:col-6 pl-0 xl:pl-4 lg:pl-4">


                                            <div class="flex gap-2 align-items-center mb-2">
                                                <p-checkbox 
                                                    [binary]="true" 
                                                    formControlName="credentialAny"
                                                    inputId="credentialAny"
                                                    class="mr-1"/>
                                                <label class="mr-2" for="credentialAny">Any</label>
                                                <p-floatLabel>
                                                    <p-multiSelect 
                                                        [style]="{ width: '30vw' }"
                                                        inputId="float-label" 
                                                        formControlName="dynamicCredentialTypes" 
                                                        [options]="credentialTypes"
                                                        optionLabel="val" />
                                                    <label for="float-label">Credential Type</label>
                                                </p-floatLabel>
                                            </div>
                                            
                                            @if((infoForm['dynamicCredentialTypes'].dirty || infoForm['dynamicCredentialTypes'].touched || submitted)  && !(getFormValue(informationForm, 'dynamicCredentialTypes').length) && !getFormValue(informationForm, 'credentialAny')){
                                                <div class="p-error ">This is required.</div>
                                            }
                                        </div>

                                        
                                    }
                                    <div class="p-0 mb-2 col-12">
                                        <span class="text-base font-semibold">Skills and Experience</span>
                                    </div>
<!--                                     <div class="p-0 mb-4 col-12 xl:col-6 lg:col-6">
                                        <p-floatLabel>
                                            <p-dropdown (onChange)="onChangeDynamicCriteriaList()" 
                                                formControlName="dynamicRoleCriteria" 
                                                inputId="float-label" 
                                                [options]="dynamicRoleCriteriaList"
                                                optionLabel="name" />
                                            <label for="float-label">Criteria Template</label>
                                        </p-floatLabel>
                                    </div> -->
                                    <div class="p-0 mb-4 col-12 xl:col-6 lg:col-6">
                                        <p-floatLabel>
                                            <p-multiSelect 
                                            inputId="float-label" 
                                            formControlName="dynamicRoleCriteria" 
                                            [options]="dynamicRoleCriteriaList"
                                            (onChange)="onChangeDynamicCriteriaList()"
                                            optionLabel="name" />
                                            <!-- <p-dropdown (onChange)="onChangeDynamicCriteriaList()" 
                                                formControlName="dynamicRoleCriteria" 
                                                inputId="float-label" 
                                                [options]="dynamicRoleCriteriaList"
                                                optionLabel="name" /> -->
                                            <label for="float-label">Criteria Template</label>
                                        </p-floatLabel>
                                    </div>
                                    @if (selectedDynamicCriteria) {
                                        <div class="p-0 mb-4 col-12 xl:col-6 lg:col-6 pl-0 xl:pl-4 lg:pl-4">

                                            <div class="flex gap-2 align-items-center mb-2">
                                                <p-checkbox 
                                                    [binary]="true" 
                                                    formControlName="criteriaAny"
                                                    inputId="criteriaAny"
                                                    class="mr-1"/>
                                                <label for="criteriaAny">Any</label>
                                                <p-floatLabel>
                                                    <p-multiSelect 
                                                        [style]="{ width: '30vw' }"
                                                        inputId="float-label" 
                                                        formControlName="dynamicRoleCriteriaTypes" 
                                                        [options]="criteriaTypes"
                                                        optionLabel="val" />
                                                    <label for="float-label">Criteria Type</label>
                                                </p-floatLabel>
                                            </div>
                                            
                                            @if((infoForm['dynamicRoleCriteriaTypes'].dirty || infoForm['dynamicRoleCriteriaTypes'].touched || submitted) && !(getFormValue(informationForm, 'dynamicRoleCriteriaTypes').length) && !getFormValue(informationForm, 'criteriaAny') ){
                                                <div class="p-error ">This is required.</div>
                                            }
                                        </div>
                                    }
                                    <div class="p-0 mb-4 col-12">
                                        <div class="flex justify-content-start">
                                            <div class="flex flex-wrap gap-3">
                                                <span class="text-base font-semibold">Citizenship</span>
                                                <div class="flex align-items-center">
                                                    <p-radioButton formControlName="immigrationStatus" value="any"/>
                                                    <label class="ml-2">
                                                        Any 
                                                    </label>
                                                </div>
                                                <div class="flex align-items-center">
                                                    <p-radioButton formControlName="immigrationStatus" value="US Citizen" />
                                                    <label class="ml-2">
                                                        US
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="p-0 mb-4 col-12">
                                        <div class="flex justify-content-start">
                                            <div class="flex flex-wrap gap-3">
                                                <span class="text-base font-semibold">Is this role remote?</span>
                                                <div class="flex align-items-center">
                                                    <p-radioButton formControlName="isRemote" [value]="true" />
                                                    <label class="ml-2">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div class="flex align-items-center">
                                                    <p-radioButton formControlName="isRemote" [value]="false"/>
                                                    <label class="ml-2">
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    @if(!getFormValue(informationForm, 'isRemote') && firmLocation && firmLocation.location){
                                        <div class="p-0 mb-4 col-12 xl:col-6 lg:col-6">
                                            <p-floatLabel>
                                                <input 
                                                    [(ngModel)]="firmLocation.location.officeName"
                                                    [ngModelOptions]="{standalone: true}"
                                                    type="text" 
                                                    pInputText placeholder="Enter the office name"
                                                    [required] = "!getFormValue(informationForm, 'isRemote')"
                                                    #officeName="ngModel"/>
                                                <label for="username">Enter the office name</label>
                                            </p-floatLabel>
                                            @if(officeName.errors?.['required']){
                                                <div class="p-error">This is required.</div>
                                            }
                                        </div>
                                        <p class="p-0 mb-4 col-12 xl:col-6 lg:col-6 pl-0 xl:pl-4 lg:pl-4 mt-2">OR</p>
                                        <div class="p-0 mb-4 col-12 xl:col-6 lg:col-6">
                                            <p-floatLabel>
                                                <p-dropdown     
                                                    [(ngModel)]="firmLocation"
                                                    [ngModelOptions]="{standalone: true}"
                                                    [options]="firmLocationsList"
                                                    optionLabel="location.officeName"
                                                   />
                                                <label for="username">Choose a previous location</label>
                                            </p-floatLabel>
                                        </div>
                                        <div class="p-0 mb-4 col-12 xl:col-6 lg:col-6 pl-0 xl:pl-4 lg:pl-4">
                                            <p-floatLabel>
                                                <input 
                                                    [(ngModel)]="firmLocation.location.street"
                                                    [ngModelOptions]="{standalone: true}"
                                                    type="text" 
                                                    pInputText 
                                                    placeholder="What is the street address"
                                                    [required] = "!getFormValue(informationForm, 'isRemote')"
                                                    #street="ngModel"/>
                                                <label for="username">What is the street address</label>
                                            </p-floatLabel>
                                            @if(street.errors?.['required']){
                                                <div class="p-error">This is required.</div>
                                            }
                                        </div>

                                        <div class="p-0 mb-4 col-12 xl:col-6 lg:col-6">
                                            <p-floatLabel>
                                                <input 
                                                    [(ngModel)]="firmLocation.location.suiteNo"
                                                    [ngModelOptions]="{standalone: true}"
                                                    type="text" 
                                                    pInputText placeholder="What is the suite number"
                                                    [required] = "!getFormValue(informationForm, 'isRemote')"
                                                    #suite="ngModel"/>
                                                <label for="username">What is the suite number</label>
                                            </p-floatLabel>
                                            @if(suite.errors?.['required']){
                                                <div class="p-error">This is required.</div>
                                            }
                                        </div>
                                        <div class="p-0 mb-4 col-12 xl:col-6 lg:col-6 pl-0 xl:pl-4 lg:pl-4">
                                            <p-floatLabel>
                                                <input 
                                                    [(ngModel)]="firmLocation.location.city"
                                                    [ngModelOptions]="{standalone: true}"
                                                    type="text" 
                                                    pInputText 
                                                    placeholder="In which city"
                                                    [required] = "!getFormValue(informationForm, 'isRemote')"
                                                    #city="ngModel"/>
                                                <label for="username">In which city</label>
                                            </p-floatLabel>
                                            @if(city.errors?.['required']){
                                                <div class="p-error">This is required.</div>
                                            }
                                        </div>

                                        <div class="p-0 mb-4 col-12 xl:col-6 lg:col-6">
                                            <p-floatLabel>
                                                <input 
                                                    [(ngModel)]="firmLocation.location.state"
                                                    [ngModelOptions]="{standalone: true}"
                                                    type="text" 
                                                    pInputText placeholder="State"
                                                    [required] = "!getFormValue(informationForm, 'isRemote')"
                                                    #state="ngModel"/>
                                                <label for="username">State</label>
                                            </p-floatLabel>
                                            @if(state.errors?.['required']){
                                                <div class="p-error">This is required.</div>
                                            }
                                        </div>
                                        <div class="p-0 mb-4 col-12 xl:col-6 lg:col-6 pl-0 xl:pl-4 lg:pl-4">
                                            <p-floatLabel>
                                                <input 
                                                    [(ngModel)]="firmLocation.location.zipCode"
                                                    [ngModelOptions]="{standalone: true}"
                                                    type="text" 
                                                    pInputText 
                                                    pattern="^\b\d{5}(-\d{4})?\b$"
                                                    placeholder="What is the zipcode"
                                                    #zipCode="ngModel"
                                                    [required] = "!getFormValue(informationForm, 'isRemote')"/>
                                                <label for="username">What is the zipcode</label>
                                            </p-floatLabel>
                                            @if(zipCode.errors?.['pattern']){
                                                <div class="p-error">Invalid zip code.</div>
                                            }
                                            @if(zipCode.errors?.['required']){
                                                <div class="p-error">This is required.</div>
                                            }
                                        </div>
                                    }
                                    <div class="p-0 mb-2 col-12">
                                        <span class="text-base font-semibold">Point of Contact information</span>
                                    </div>
                                    <div class="p-0 mb-4 col-12 xl:col-6 lg:col-6" formGroupName="reviewCenterLocation">
                                        <p-floatLabel>
                                            <input formControlName="pointOfContact" type="text" pInputText/>
                                            <label for="username">Name</label>
                                        </p-floatLabel>
                                        @if(informationForm.get('reviewCenterLocation.pointOfContact')?.dirty || informationForm.get('reviewCenterLocation.pointOfContact')?.touched || submitted){
                                            @if(informationForm.get('reviewCenterLocation.pointOfContact')?.errors?.['required']){
                                                <div class="p-error ">This is required.</div>
                                            }
                                            @if(informationForm.get('reviewCenterLocation.pointOfContact')?.errors?.['pattern']){
                                                <div class="p-error ">Invalid Point of contact.</div>
                                            }
                                        }
                                    </div>
                                    <div class="p-0 mb-4 col-12 xl:col-6 lg:col-6 pl-0 xl:pl-4 lg:pl-4" formGroupName="reviewCenterLocation">
                                        <p-floatLabel>
                                            <input formControlName="email" type="email" pInputText />
                                            <label for="username">Email</label>
                                        </p-floatLabel>
                                        @if((informationForm.get('reviewCenterLocation.email')?.dirty || informationForm.get('reviewCenterLocation.email')?.touched || submitted)){
                                            @if(!getNestedInformationFormValue('reviewCenterLocation', 'email') && !getNestedInformationFormValue('reviewCenterLocation', 'phoneNumber')){
                                                <div class="p-error ">This is required.</div>
                                            }
                                            @if(informationForm.get('reviewCenterLocation.email')?.errors?.['pattern']){
                                                <div class="p-error ">Invalid Email.</div>
                                            }
                                        }
                                    </div>
                                    <div class="p-0 mb-2 col-12">
                                        <span class="text-base font-semibold">For assistance, which phone number should candidates or cyber professionals call?</span>
                                    </div>
                                    <div class="p-0 mb-4 col-12 xl:col-6 lg:col-6" formGroupName="reviewCenterLocation">
                                        <p-floatLabel>
                                            <input formControlName="phoneNumber" type="text" pInputText/>
                                            <label for="username">Contact </label>
                                        </p-floatLabel>
                                        @if((informationForm.get('reviewCenterLocation.phoneNumber')?.dirty || informationForm.get('reviewCenterLocation.phoneNumber')?.touched) || submitted  ){
                                            @if(!getNestedInformationFormValue('reviewCenterLocation', 'email') && !getNestedInformationFormValue('reviewCenterLocation', 'phoneNumber')){
                                                <div class="p-error ">This is required.</div>
                                            }
                                            @if(informationForm.get('reviewCenterLocation.phoneNumber')?.errors?.['pattern']){
                                                <div class="p-error ">Invalid phone number.</div>
                                            }
                                        }
                                    </div>
                                    <div class="p-0 mb-2 col-12">
                                        <span class="text-base font-semibold">What Instructions for candidates/cyber professionals should be conveyed?</span>
                                    </div>
                                    <div class="p-0 mb-4 col-12" formGroupName="reviewCenterLocation">
                                        <textarea 
                                        rows="5"
                                        cols="30" 
                                        pInputTextarea 
                                        formControlName="instructions"
                                        placeholder="Write Message...">
                                        </textarea>
                                    </div>
                                </div>
                                <div class="col-12 p-0 flex justify-content-end gap-3">
                                    @if(roleId)
                                    {
                                        <p-button label="Cancel" (click)="onCancel()" [outlined]="true" [rounded]="true" size="large"/>
                                        <p-button [disabled]="!(informationForm.valid && schedulingForm.valid && isReviewerValidated())" (click)="onSubmit()" label="{{btnlabel}}" [rounded]="true" size="large"/>
                                    } 
                                    @else{
                                        <p-button label="Cancel" (click)="onCancel()" [outlined]="true" [rounded]="true" size="large"/>
                                        <p-button [disabled]="!(informationForm.valid && isReviewerValidated())" type="submit" label="Next" [rounded]="true" size="large" />
                                    }
                                </div>
                            </form>
                            </p-tabPanel>  
                            <p-tabPanel [disabled]="!(informationForm.valid && isReviewerValidated())" header="SCHEDULING">
                                <form [formGroup]="schedulingForm" (ngSubmit)="onSubmitSchedulingForm()">
                                    <div class="grid grid-nogutter">
                                        <div class="p-0 pt-6 col-12 xl:col-6 lg:col-6">
                                            <p-floatLabel>
                                                @if(roleId && isProjectStarted && isRoleStarted){
                                                    <p-calendar
                                                        formControlName="startDate"
                                                        placeholder="mm/dd/yy"/>
                                                }
                                                @else {
                                                    <p-calendar
                                                    formControlName="startDate"
                                                    [minDate]="roleMinDate"
                                                    [maxDate]="roleMaxDate"
                                                    placeholder="mm/dd/yy"/>
                                                }
                                                <label>Start Date</label>
                                            </p-floatLabel>
                                            @if(scheduleForm['startDate'].dirty || scheduleForm['startDate'].touched || submitted){
                                                @if(scheduleForm['startDate'].errors?.['required']){
                                                    <div class="p-error ">This is required.</div>
                                                }
                                            }
                                        </div>
                                        <div class="p-0 mb-1 col-12 xl:col-6 lg:col-6 pl-0 xl:pl-4 lg:pl-4">
                                            <label for="time" class="text-sm font-medium block">Start Time</label>
                                                <p-calendar 
                                                inputId="calendar-timeonly" 
                                                formControlName="startTime"
                                                [timeOnly]="true" 
                                                hourFormat="12"
                                                inline="true"
                                                styleClass="slider-style"
                                                />
                                        </div>
                                        <div class="p-0 mb-2 col-12 xl:col-6 lg:col-6">
                                            <p-floatLabel>
                                                <p-dropdown 
                                                    formControlName="timezone"
                                                    [options]="timezones"
                                                    optionLabel="name" />
                                                <label for="float-label">Timezone</label>
                                            </p-floatLabel>
                                        </div>
                                        <div class="p-0 mb-4 col-12 xl:col-6 lg:col-6 pl-0 xl:pl-4 lg:pl-4">
                                            <p-floatLabel>
                                                @if(roleId && isProjectStarted && isRoleStarted){
                                                    <p-calendar
                                                    formControlName="displayStaffBy"
                                                    placeholder="mm/dd/yy"/>
                                                }
                                                @else{
                                                    <p-calendar
                                                        formControlName="displayStaffBy"
                                                        placeholder="mm/dd/yy"
                                                        [minDate]="staffByMinDate"
                                                        [maxDate]="staffByMaxDate"/>
                                                }
                                                <label>Staff By Date </label>
                                            </p-floatLabel>
                                            @if(!isGreaterThanTheCurrentDate){
                                                <div class="p-error ">
                                                    Staff by date must be after the current date.
                                                </div>
                                            }
                                            @if(!isStaffByDateLessThanRoleStartDate){
                                                <div class="p-error ">
                                                    Staff by date must be before role start date.
                                                </div>
                                            }
                                            @if(scheduleForm['displayStaffBy'].dirty || scheduleForm['displayStaffBy'].touched || submitted){
                                                @if(scheduleForm['displayStaffBy'].errors?.['required']){
                                                    <div class="p-error ">This is required.</div>
                                                }
                                            }

                                        </div>
                                        <div class="p-0 mb-3 col-12">
                                            <span class="text-base font-semibold">How long is the Role Duration</span>
                                        </div>
                                        <div class="p-0 mb-1 xl:mb-4 lg:mb-4 col-12 xl:col-6 lg:col-6">
                                            <p-floatLabel >
                                                <p-dropdown
                                                    formControlName="duration"
                                                    [options]="roleDurations"
                                                    optionLabel="label" />
                                            </p-floatLabel>
                                            @if(((scheduleForm['duration'].dirty || scheduleForm['duration'].touched) || submitted) && !getFormValue(schedulingForm, 'duration')){
                                                <div class="p-error ">This is required.</div>
                                            }
                                        </div>
                                        <div class="p-0 col-12 xl:col-6 lg:col-6"></div>
                                        <div class="p-0 mb-3 col-12">
                                            <span class="text-base font-semibold">What are the weekly Expected Hours</span>
                                        </div>
                                        <div class="p-0 mb-1 xl:mb-4 lg:mb-4 col-12 xl:col-6 lg:col-6">
                                            <p-floatLabel >
                                                <p-dropdown
                                                    formControlName="workHours"
                                                    [options]="weeklyExpectedHours"
                                                    optionLabel="label" />
                                            </p-floatLabel>
                                            @if(((scheduleForm['workHours'].dirty || scheduleForm['workHours'].touched) || submitted) && !getFormValue(schedulingForm, 'workHours')){
                                                <div class="p-error ">This is required.</div>
                                            }
                                        </div>
                                        <div class="p-0 mb-2 col-12 xl:col-6 lg:col-6"></div>
                                        <div class="p-0 mb-4 col-12">
                                            <span class="text-base font-semibold">Select Expected Days and Timing</span>
                                        </div>
            
                                        <div class="p-0 mb-4 col-12 xl:col-4 lg:col-4 flex align-items-center gap-4">
                                            <p-checkbox 
                                            [binary]="true" 
                                            formControlName="weekDaysWillWork"/>
                                            <label>Monday - Friday</label>
                                        </div>
            
                                        @if(getFormValue(schedulingForm, 'weekDaysWillWork')){
                                            <div class="p-0 mb-4 col-12 xl:col-4 lg:col-4 pl-0 xl:pl-4 lg:pl-4">
                                                <label for="time" class="text-sm font-medium block">Start</label>
                                                <p-calendar 
                                                    formControlName="weekdayStartTime"
                                                    inputId="calendar-timeonly" 
                                                    [timeOnly]="true" 
                                                    hourFormat="12"
                                                    inline="true"
                                                    styleClass="slider-style"
                                                    />
                                            </div>
                
                                            <div class="p-0 mb-4 col-12 xl:col-4 lg:col-4 pl-0 xl:pl-4 lg:pl-4">
                                                <label for="time" class="text-sm font-medium block">End</label>
                                                <p-calendar 
                                                    formControlName="weekdayEndTime"
                                                    inputId="calendar-timeonly" 
                                                    [timeOnly]="true" 
                                                    hourFormat="12"
                                                    inline="true"
                                                    styleClass="slider-style"
                                                />
                                            </div>
                                        } @else {
                                            <div class="p-0 mb-4 col-12 xl:col-4 lg:col-4 pl-0 xl:pl-4 lg:pl-4">
                                            </div>
                                            <div class="p-0 mb-4 col-12 xl:col-4 lg:col-4 pl-0 xl:pl-4 lg:pl-4">
                                            </div>
                                        }
            
                                        <div class="p-0 mb-4 col-12 xl:col-4 lg:col-4 flex align-items-center gap-4">
                                            <p-checkbox 
                                                [binary]="true" 
                                                formControlName="saturdayWillWork"
                                                (onChange)="onSaturdayCheck($event)" 
                                                />
                                                <label>Saturday</label>
                                                </div>
        
                                        @if (getFormValue(schedulingForm, 'saturdayWillWork')) {
                                            <div class="p-0 mb-4 col-12 xl:col-4 lg:col-4 pl-0 xl:pl-4 lg:pl-4">
                                                <label for="time" class="text-sm font-medium block">Start</label>
                                                <p-calendar 
                                                    formControlName="saturdayStartTime"
                                                    inputId="calendar-timeonly" 
                                                    [timeOnly]="true" 
                                                    hourFormat="12"
                                                    inline="true"
                                                    styleClass="slider-style"
                                                    />
                                            </div>
                
                                            <div class="p-0 mb-4 col-12 xl:col-4 lg:col-4 pl-0 xl:pl-4 lg:pl-4">
                                                <label for="time" class="text-sm font-medium block">End</label>
                                                <p-calendar 
                                                    formControlName="saturdayEndTime"
                                                    inputId="calendar-timeonly" 
                                                    [timeOnly]="true" 
                                                    hourFormat="12"
                                                    inline="true"
                                                    styleClass="slider-style"
                                                />
                                            </div>
                                        } @else {
                                            
                                            <div class="p-0 mb-4 col-12 xl:col-4 lg:col-4 pl-0 xl:pl-4 lg:pl-4">
                                            </div>
                                            <div class="p-0 mb-4 col-12 xl:col-4 lg:col-4 pl-0 xl:pl-4 lg:pl-4">
                                            </div>
                                        }
            
                                        <div class="p-0 mb-4 col-12 xl:col-4 lg:col-4 flex align-items-center gap-4">
                                            <p-checkbox 
                                            [binary]="true" 
                                            formControlName="sundayWillWork"
                                            (onChange)="onSundayCheck($event)" 
                                            />
                                            <label>Sunday</label>
                                        </div>
            
                                        @if (getFormValue(schedulingForm, 'sundayWillWork')) {
                                            <div class="p-0 mb-4 col-12 xl:col-4 lg:col-4 pl-0 xl:pl-4 lg:pl-4">
                                                <label for="time" class="text-sm font-medium block">Start</label>
                                                <p-calendar 
                                                    formControlName="sundayStartTime"
                                                    inputId="calendar-timeonly" 
                                                    [timeOnly]="true" 
                                                    hourFormat="12"
                                                    inline="true"
                                                    styleClass="slider-style"
                                                />
                                            </div>
                
                                            <div class="p-0 mb-4 col-12 xl:col-4 lg:col-4 pl-0 xl:pl-4 lg:pl-4">
                                                <label for="time" class="text-sm font-medium block">End</label>
                                                <p-calendar 
                                                    formControlName="sundayEndTime"
                                                    inputId="calendar-timeonly" 
                                                    [timeOnly]="true" 
                                                    hourFormat="12"
                                                    inline="true"
                                                    styleClass="slider-style"
                                                />
                                            </div>
                                        } @else {
                                            <div class="p-0 mb-4 col-12 xl:col-4 lg:col-4 pl-0 xl:pl-4 lg:pl-4">
                                            </div>
                                            <div class="p-0 mb-4 col-12 xl:col-4 lg:col-4 pl-0 xl:pl-4 lg:pl-4">
                                            </div>
                                        }
                                    </div>
                                     <div class="col-12 p-0 flex justify-content-end gap-3">
                                        @if(roleId)
                                        {
                                            <p-button label="Cancel" (click)="onCancel()" [outlined]="true" [rounded]="true" size="large"/>
                                            <p-button [disabled]="!(informationForm.valid && schedulingForm.valid && isReviewerValidated())" (click)="onSubmit()" label="{{btnlabel}}" [rounded]="true" size="large"/>
                                        } 
                                        @else {
                                            <p-button label="Cancel" (click)="onCancel()" [outlined]="true" [rounded]="true" size="large"/>
                                            <p-button [disabled]="!schedulingForm.valid" type="submit" label="Next" [rounded]="true" size="large"/>
                                        }
                                    </div>
                                </form>
                            </p-tabPanel>  
                            <p-tabPanel [disabled]="!(informationForm.valid && schedulingForm.valid && isReviewerValidated())" header="SCREENING QUESTIONS ">
                                <form [formGroup]="screeningForm" (ngSubmit)="onSubmit()">
                                <div class="grid grid-nogutter">
                                    <div class="p-0 mb-5 col-12">
                                        <div class="flex justify-content-start">
                                            <div class="flex flex-wrap gap-3">
                                                <span class="text-base font-semibold">Does this role have Screening questions?</span>
                                                <div class="flex align-items-center w-full xl:w-auto lg:w-auto">
                                                    <p-radioButton [value]="true" formControlName="hasConflict" />
                                                    <label class="ml-2">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div class="flex align-items-center">
                                                    <p-radioButton [value]="false" formControlName="hasConflict" />
                                                    <label class="ml-2">
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    @if (screeningForm.controls['hasConflict'].getRawValue()) {
                                        <div class="col-12">
                                            <span class="text-sm mb-3 block">
                                                Enter any required screening questions below</span>
                                        </div>
                                        <div class="col-12 lg:col-4 xl:col-4">
                                            <p-floatLabel>
                                                <input formControlName="question" type="text" pInputText  placeholder="Enter your Yes-No questions here"/>
                                                <label for="username">Specific Questions</label>
                                            </p-floatLabel>
                                        </div>
                                        <div class="col-12 lg:col-8 xl:col-8 pl-0 xl:pl-3 lg:pl-3 pt-3 xl:pt-0 lg:pt-0 flex justify-content-end xl:justify-content-start lg: justify-content-start">
                                            <p-button label="ADD" (click)="addQuestion()" icon="pi pi-plus" [rounded]="true" size="large"/>
                                        </div>
                                        <div class="col-12">
                                            <ul cdkDropList class="m-0 mt-3 dragable-list list-none" (cdkDropListDropped)="drop($event)">
                                                @for(product of conflictQuestions; track product; let i = $index){
                                                    <li class="col-12 xl:col-10 pl-2 xl:pl-6 lg:pl-6 p-2 pb-0 align-items-center border-300 border-bottom-1 xl:border-none list-none surface-100" cdkDrag>
                                                        <div class="grid grid-nogutter">
                                                            <div class="col-12 xl:col-5 border-none xl:border-bottom-1 border-300 white-space-normal text-sm font-normal">{{ product.title }}</div>
                                                            <div class="col-6 xl:col-3 pl-1 xl:pl-3 lg:pl-3  p-0 py-2">
                                                                <div class="flex justify-content-start">
                                                                    <div class="flex flex-wrap gap-3">
                                                                        <div class="flex align-items-center">
                                                                            <p-radioButton [(ngModel)]="product.answer"
                                                                            [ngModelOptions]="{standalone: true}" [value]="true"  />
                                                                            <label  class="ml-2">
                                                                                Yes
                                                                            </label>
                                                                        </div>
                                                                        <div class="flex align-items-center">
                                                                            <p-radioButton [(ngModel)]="product.answer"
                                                                            [ngModelOptions]="{standalone: true}" [value]="false" />
                                                                            <label class="ml-2">
                                                                                No
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-6 xl:col-4 lg:col-4 pl-0 flex justify-content-end gap-4 align-items-center icons">
                                                                <span (click)="removeQuestion(i)" class="red material-symbols-outlined cursor-pointer">
                                                                    delete
                                                                </span>
                                                                <span class="material-symbols-outlined text-gray-600">
                                                                    drag_indicator
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </li>
                                                }
                                            </ul>
                                        </div>
                                    }
                                    
                                </div>
                                <div class="col-12 p-0 flex justify-content-end gap-3">
                                   @if(roleId)
                                    {
                                        <p-button label="Cancel" (click)="onCancel()" [outlined]="true" [rounded]="true" size="large"/>
                                        <p-button [disabled]="!(informationForm.valid && schedulingForm.valid && isReviewerValidated())" type="submit" label="{{btnlabel}}" [rounded]="true" size="large"/>
                                    } 
                                    @else
                                    {
                                        <p-button label="Cancel" (click)="onCancel()" [outlined]="true" [rounded]="true" size="large"/>
                                        <p-button [disabled]="!(informationForm.valid && schedulingForm.valid && isReviewerValidated())" type="submit" label="{{btnlabel}}" [rounded]="true" size="large"/>
                                    }
                                </div>
                            </form>
                            </p-tabPanel>
                        </p-tabView>
                </div>
            </div>
        </div>
    </div>
    <!--   -->

    <p-dialog 
            [modal]="true" 
            draggable="false"
            [(visible)]="visibleSaveModal"
            [breakpoints]="{ '1199px': '75vw', '767px': '90vw' }" 
            [style]="{ width: '660px' }" >

            <ng-template pTemplate="header">
                <div class="inline-flex align-items-center justify-content-center gap-2">
                    <span class="font-bold white-space-nowrap">
                        Would you like the scheduling information and screening questions applied <br> to future roles on the project?
                    </span>
                </div>
            </ng-template>

            <div class="flex-row">
                <div class="flex align-items-center">
                    <p-checkbox 
                        [(ngModel)]="applyScheduling"
                        label="Apply Scheduling Information to all future roles?"
                        [binary]="true" 
                        inputId="binary" />
                </div>
                <div class="flex align-items-center pt-3">
                    <p-checkbox 
                        [(ngModel)]="applyConflictQuestions"
                        label="Apply Screening Questions to all future roles?"
                        [binary]="true" 
                        inputId="binary"  />
                </div>
            </div>

            <ng-template pTemplate="footer">
                <p-button label="Apply & Continue" size="large" [rounded]="true" (onClick)="saveRole()"/>
                <p-button label="Cancel" size="large" [rounded]="true" (click)="visibleSaveModal = false"/>
            </ng-template>
    </p-dialog>

   
}
