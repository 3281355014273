<div class="col-12">
  <input
    type="file"
    #fileUpload
    style="display: none"
    (change)="onFileSelect($event)"
  />
  <p-button
    (click)="fileUpload.click()"
    label="Upload Document"
    rounded="true"
    size="large"
    icon="pi pi-plus"
  ></p-button>
  @if(newFile){
      <div class="flex align-items-center justify-content-between mt-4 mb-5">
        <span>{{ newFile.name }} </span>
        <p-button class="ml-4" size="small" (click)="removeFile()">
          <span class="red material-symbols-outlined"> delete </span>
        </p-button>
      </div>
  }
  @if(showDetailsInput){
  <form [formGroup]="fileDetails">
      <div class="m-3">
        <p-floatLabel>
            <input formControlName="docTitle" type="text" pInputText />
            <label for="docTitle">Title</label>
        </p-floatLabel>
      </div>
      <div class="m-3">
          <p-floatLabel>
            <textarea
              class="w-12"
              formControlName="userTitle"
              rows="5"
              pInputTextarea
            ></textarea>
            <label for="userTitle">Instructions for Freelancers</label>
          </p-floatLabel>
      </div>
      <div class="m-3">
        <p-button label="Upload" size="small"  (click)="uploadFile()">
            <span  class="material-symbols-outlined"> upload </span>
        </p-button>
       </div>
    </form>

  }
  @for(file of files; track file){ 
    @if(file){
        <div class="flex align-items-center justify-content-between mt-4 mb-5">
            <span>{{ file.docTitle }} </span>
            <p-button class="ml-4" size="small" (click)="onDelete(file._id)">
            <span class="red material-symbols-outlined"> delete </span>
            </p-button>
        </div>
     } 
}
</div>
