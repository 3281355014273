<p-table styleClass="p-datatable-sm p-datatable-sm--normal" [value]="responseData" dataKey="_id" [tableStyle]="{ 'min-width': '60rem' }"
[expandedRowKeys]="expandedRows" (onRowExpand)="onRowExpand($event)" (onRowCollapse)="onRowCollapse($event)">
                <ng-template pTemplate="header">
                    <tr>
                        <th>
                            Role
                        </th>
                        <th>
                            Position
                        </th>
                        <th>
                            Last Offer Sent
                        </th>
                        <th>
                            Received
                        </th>
                        <th style="width:10%;">Action</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" class="border-0" let-responseData let-expanded="expanded">
                    <tr>
                        <td>{{ responseData.roleType }}</td>
                        <td> {{ responseData.positions }} Listed / {{ responseData.positionsFilled }} Filled</td>
                        <td>
                            @if (responseData.lastOfferSent) {
                            {{responseData.lastOfferSent | date:'h:mma MM/dd/yyyy ' }}
                            } @else {
                            -
                            }
                        </td>
                        <td>{{ responseData.offerQueuesCount }}</td>
                        <td>
                            <div class="wrap-actions flex align-items-center gap-2">
                              <div  pTooltip="Fire" 
                              tooltipPosition="top">
                                <button [disabled]="responseData.disableCancelOfferButton" pButton pRipple type="button"
                                    class="p-button-rounded p-button-text">
                                    <span class="material-symbols-outlined"> person_off </span>
                                </button>
                              </div>
                                @if(responseData.offers.viewed.length || responseData.offers.unViewed.length ||
                                responseData.offers.rejected.length){
                                <button  pTooltip="Download" 
                                tooltipPosition="top" pButton pRipple type="button" class="p-button-rounded p-button-text">
                                    <span class="material-symbols-outlined"> download </span>
                                </button>
                                }

                                <p-button [pTooltip]="expanded? 'Close' : 'Expand'" 
                                tooltipPosition="top" type="button" pRipple [pRowToggler]="responseData" [text]="true" [rounded]="true"
                                    [plain]="true" [icon]="
                          expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-up'
                        " class="row-toggler" />
                            </div>
                        </td>
                    </tr>
                </ng-template>
                 <ng-template pTemplate="rowexpansion" let-product>
                    <tr>
                      <td colspan="7" class="p-0">
                        <div class="sub-details py-3 px-4">
                          <div class="grid grid-nogutter">
                            <div class="py-0 pr-4 pl-0 col-12 md:col-12 sm:col-12">
                              <span class="text-base block font-medium mt-3">Project Type</span>
                            </div>
                            <div class="py-0 pr-4 pl-0 col-2 md:col-2 sm:col-2">
                              @for(job of roleDetailsDisplay?.jobType; track job){
                                <p class="m-0 text-black-alpha-60 font-medium text-sm">
                                  {{ job }}
                                </p>
                              }
                            </div>
                            <div class="py-0 pr-4 pl-0 col-12 md:col-12 sm:col-12">
                              <span class="text-base block font-medium mt-3">Education</span>
                            </div>
                            <div class="py-0 pr-4 pl-0 col-2 md:col-2 sm:col-2">
                              <span class="text-primary mt-2 block text-xs">Under Graduate</span>
                             @if(roleDetailsDisplay?.underGrad?.length){
                                @for(education of roleDetailsDisplay?.underGrad; track education){
                                    <p class="m-0 text-black-alpha-60 font-medium text-sm">{{education.type}}-{{education.major}}</p>
                                }
                              } 
                              @else{
                                <p class="m-0 text-black-alpha-60 font-medium text-sm">Any</p>
                              }      
                            </div>
                            <div class="py-0 pr-4 pl-0 col-2 md:col-2 sm:col-2">
                            <span class="text-primary mt-2 block text-xs">Graduate</span>
                            @if(roleDetailsDisplay?.graduate?.length){
                                @for(education of roleDetailsDisplay?.graduate; track education){
                                    <p class="m-0 text-black-alpha-60 font-medium text-sm">{{education.type}}-{{education.major}}</p>
                                }
                              }
                              @else{
                                <p class="m-0 text-black-alpha-60 font-medium text-sm">Any</p>
                              }
                            </div>
                            <div class="py-0 pr-4 pl-0 col-12 md:col-12 sm:col-12">
                              <span class="text-base block font-medium mt-3">Skills and Experience</span>
                            </div>
                            @if(roleDetailsDisplay?.skillsAndExperience?.length){
                              <div class="py-0 pr-4 pl-0 col-2 md:col-2 sm:col-2">
                                @for(skill of roleDetailsDisplay?.skillsAndExperience; track skill){
                                  <p class="m-0 text-black-alpha-60 font-medium text-sm">{{skill}}</p>
                                }
                              </div>
                            }
                            @else{
                              <p class="m-0 text-black-alpha-60 font-medium text-sm">Any</p>
                            }
                            <div class="py-0 pr-4 pl-0 col-12 md:col-12 sm:col-12">
                              <span class="text-base block font-medium mt-3">Credentials</span>
                            </div>
                            @if(roleDetailsDisplay?.credential?.length){
                              <div class="py-0 pr-4 pl-0 col-2 md:col-2 sm:col-2">
                                @for(cred of roleDetailsDisplay?.credential; track cred){
                                  <p class="m-0 text-black-alpha-60 font-medium text-sm">{{cred.val}}</p>
                                }
                              </div>
                            }
                            @else{
                              <p class="m-0 text-black-alpha-60 font-medium text-sm">Any</p>
                            }
                            <div class="py-0 pr-4 pl-0 col-12 md:col-12 sm:col-12">
                              <span class="text-base block font-medium mt-3">Timeline and Schedule</span>
                            </div>
                            <div class="py-0 pr-4 pl-0 col-2 md:col-2 sm:col-2">
                              <span class="text-primary mt-2 block text-xs">Starts On</span>
                              <p class="m-0 text-black-alpha-60 font-medium text-sm">
                                {{ roleDetails.startAt | date : "MMM dd YYYY" }}
                              </p>
                            </div>
                            <div class="py-0 pr-4 pl-0 col-2 md:col-2 sm:col-2">
                              <span class="text-primary mt-2 block text-xs">Monday - Friday</span>
                              @if(roleDetails.workSchedule.schedule.weekDays.willWork){
                              <p class="m-0 text-black-alpha-60 font-medium text-sm">
                                Start {{ formatTime(roleDetails.workSchedule.schedule.weekDays.time.start) }}
                              </p>
                              <p class="m-0 text-black-alpha-60 font-medium text-sm">
                                End {{ formatTime(roleDetails.workSchedule.schedule.weekDays.time.end) }}
                              </p>
                              }@else{
                              <p class="m-0 text-black-alpha-60 font-medium text-sm">
                                Review Center
                              </p>
                              <p class="m-0 text-black-alpha-60 font-medium text-sm">
                                will be closed
                              </p>
                              }
                            </div>
                            <div class="py-0 pr-4 pl-0 col-2 md:col-2 sm:col-2">
                              <span class="text-primary mt-2 block text-xs"
                                >Expected Duration</span
                              >
                              <p class="m-0 text-black-alpha-60 font-medium text-sm">
                                {{ roleDetails.workSchedule.duration }}
                              </p>
                            </div>
                            <div class="py-0 pr-4 pl-0 col-2 md:col-2 sm:col-2">
                              <span class="text-primary mt-2 block text-xs">Saturday</span>
                              @if(roleDetails.workSchedule.schedule.saturday.willWork){
                              <p class="m-0 text-black-alpha-60 font-medium text-sm">
                                Start {{ formatTime(roleDetails.workSchedule.schedule.saturday.time.start) }}
                              </p>
                              <p class="m-0 text-black-alpha-60 font-medium text-sm">
                                End {{formatTime(roleDetails.workSchedule.schedule.saturday.time.end)}}
                              </p>
                              }@else{
                              <p class="m-0 text-black-alpha-60 font-medium text-sm">
                                Review Center
                              </p>
                              <p class="m-0 text-black-alpha-60 font-medium text-sm">Closed</p>
                              }
                            </div>
                            <div class="py-0 pr-4 pl-0 col-2 md:col-2 sm:col-2">
                              <span class="text-primary mt-2 block text-xs">Expected hours</span>
                              <p class="m-0 text-black-alpha-60 font-medium text-sm">
                                {{ roleDetails.workSchedule.workHours }}
                              </p>
                            </div>
                            <div class="py-0 pr-4 pl-0 col-2 md:col-2 sm:col-2">
                              <span class="text-primary mt-2 block text-xs">Sunday</span>
                              @if(roleDetails.workSchedule.schedule.sunday.willWork){
                              <p class="m-0 text-black-alpha-60 font-medium text-sm">
                                Start {{ formatTime(roleDetails.workSchedule.schedule.sunday.time.start) }}
                              </p>
                              <p class="m-0 text-black-alpha-60 font-medium text-sm">
                                End
                                {{ formatTime(roleDetails.workSchedule.schedule.sunday.time.end) }}
                              </p>
                              }@else{
                              <p class="m-0 text-black-alpha-60 font-medium text-sm">
                                Review Center
                              </p>
                              <p class="m-0 text-black-alpha-60 font-medium text-sm">
                                will be closed
                              </p>
                              }
                            </div>
                            <div class="py-0 pr-4 pl-0 col-12 md:col-12 sm:col-12">
                              <span class="text-base block font-medium mt-3">Screening Questions</span>
                            </div>
                            <div class="py-0 pr-4 pl-0 col-2 md:col-2 sm:col-2">
                              <span class="text-primary mt-2 block text-xs">Case Captions</span>
                              <p class="m-0 text-black-alpha-60 font-medium text-sm">
                                {{ projectDetails?.caption }}
                              </p>
                            </div>
                            @if(roleDetails.hasConflict ){

                              <div class="py-0 pr-4 pl-0 col-2 md:col-2 sm:col-2">
                                <span class="text-primary mt-2 block text-xs">Conflicts Checked Against</span>
                                @if(roleDetails.scanForConflicts.fullCompanyNames){
                                <p class="m-0 text-black-alpha-60 font-medium text-sm">
                                  Full Company Names
                                </p>
                                } @if(roleDetails.scanForConflicts.specificQuestions){
                                <p class="m-0 text-black-alpha-60 font-medium text-sm">
                                  Questions
                                </p>
                                }
                              </div>
                            @if(roleDetails.scanForConflicts.specificQuestions){

                              @if(roleDetails.conflictQuestions.length){
                                <div class="py-0 pr-4 pl-0 col-2 md:col-2 sm:col-2">
                                  <span class="text-primary mt-2 block text-xs">Questions</span>
                                  <ul class="m-0 list-none p-0 text-black-alpha-70 text-xs">
                                    @for(question of roleDetails.conflictQuestions; track question){
                                    <li>Q{{ $index + 1 }} - {{ question?.title }}</li>
                                    }
                                  </ul>
                                </div>
                                <div class="py-0 pr-4 pl-0 col-2 md:col-2 sm:col-2">
                                  <span class="text-primary mt-2 block text-xs">Answers</span>
                                  <ul class="m-0 list-none p-0 text-black-alpha-70 text-xs">
                                    @for(question of roleDetails.conflictQuestions; track question){
                                    <li>A{{ $index + 1 }} - {{ question?.answer? 'Yes': 'No' }}</li>
                                    }
                                  </ul>
                                </div>
                                }
                            }
                          } @else {
                            <div class="py-0 pr-4 pl-0 col-2 md:col-2 sm:col-2">
                              <span class="text-primary mt-2 block text-xs">Conflict Questions</span>
                              <p class="m-0 text-black-alpha-60 font-medium text-sm">No conflict questions</p>
                          </div>
                          }
                            
                          </div>
                        </div>
                      </td>
                    </tr>
                  </ng-template>
            </p-table>
